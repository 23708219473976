import { AgmCoreModule } from '@agm/core';
import { LayoutModule } from "@angular/cdk/layout";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import {MatExpansionModule} from '@angular/material/expansion'; 
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import "hammerjs";
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AboutComponent } from "./about/about.component";
import { routes } from "./app-routing";
import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./auth-interceptor";
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ForgotPasswordDialogComponent } from './dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { ShareListDialogComponent } from './dialogs/share-list-dialog/share-list-dialog.component';
import { ErrorInterceptor } from "./error-interceptor";
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { ImprintComponent } from './imprint/imprint.component';
import { LandingComponent } from "./landing/landing.component";
import { CheckListComponent as CheckListComponent } from './lists/checklist/checklist.component';
import { CompactListComponent } from "./lists/compactlist/compactlist.component";
import { CompareListComponent } from "./lists/comparelist/comparelist.component";
import { ListComponent } from './lists/list/list.component';
import { LoginComponent } from "./login/login.component";
import { ManagementComponent } from './management/management.component';
import { OverviewComponent } from "./overview/overview.component";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RatingComponent } from "./rating/rating.component";
import { AlertComponent } from './shared/alert/alert.component';
import { FooterComponent } from "./shared/footer/footer.component";
import { HeaderComponent } from "./shared/header/header.component";
import { ScrollTopComponent } from './shared/scroll-top/scroll-top.component';
import { StartProcoComponent } from './start-proco/start-proco.component';
import { ProcoStorage } from './shared/storage/storage';
import { SortCheckItemsPipe, SortProcoItemsPipe } from './utils/sort-items';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ColumnviewComponent } from './lists/columnview/columnview.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const imports = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),
  RouterModule.forRoot(routes),
  AngularFireModule.initializeApp(environment.firebase),
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyAYZCgu04PYHVYn1s3fWqh2pPoNA9vMvG8'
  }),
  AngularFireAuthModule,
  LayoutModule,
  MatToolbarModule,
  MatDialogModule,
  MatButtonModule,
  FormsModule,
  MatTableModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatOptionModule,
  MatMenuModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatIconModule,
  MatSliderModule,
  MatListModule,
  MatTooltipModule,
  MatExpansionModule,
  NgxSpinnerModule,
  NgbModule
];

export const providers = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useExisting: ErrorInterceptor, multi: true },
  SortCheckItemsPipe,
  SortProcoItemsPipe
];

export const declarations = [
  AppComponent,
  HeaderComponent,
  FooterComponent,
  LoginComponent,
  AboutComponent,
  LandingComponent,
  CompareListComponent,
  CompactListComponent,
  ListComponent,
  CheckListComponent,
  OverviewComponent,
  ForgotPasswordDialogComponent,
  CompactListComponent,
  ShareListDialogComponent,
  RatingComponent,
  ColumnviewComponent,
  FilterBarComponent,
  ImprintComponent,
  ScrollTopComponent,
  StartProcoComponent,
  PrivacyPolicyComponent,
  ManagementComponent,
  ConfirmDialogComponent,
  AlertComponent,
  ChangePasswordDialogComponent,
  SortCheckItemsPipe,
  SortProcoItemsPipe
];

@NgModule({
  declarations: declarations,
  imports: imports,
  providers: providers,
  entryComponents: [ForgotPasswordDialogComponent, ConfirmDialogComponent, ChangePasswordDialogComponent, ShareListDialogComponent],
  // bootstrapped root component
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    translate: TranslateService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");

    var savedLanguage = ProcoStorage.getLanguage();
    if (savedLanguage) {
      translate.use(savedLanguage);
      return;
    }

    const browserLang = translate.getBrowserLang();
    if (browserLang !== "de") {
      translate.use("en");
    }

    translate.use(browserLang);
    ProcoStorage.storeLanguage(browserLang);
  }

}
