import { Component, Input, OnInit, ChangeDetectorRef, QueryList, ViewChildren, ChangeDetectionStrategy } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { ListViewComponentApi } from "../../lists/comparelist/comparelist.component";
import { CompareList } from '../../model/comparelist';
import { ProcoColumn } from "../../model/prococolumn";
import { ProcoItem } from "../../model/procoitem";
import { ProcoType } from '../../model/procolist';
import { ProcoListUtils } from '../../utils/list-utils';

@Component({
  selector: "app-columnview",
  templateUrl: "./columnview.component.html",
  styleUrls: ["./columnview.component.scss"]
})
export class ColumnviewComponent implements OnInit {
  @Input() column: ProcoColumn;
  @Input() list: CompareList;
  @Input() parentApi: ListViewComponentApi;

  @ViewChildren('columnItems') columnItems: QueryList<any>;

  dataSource = new MatTableDataSource<ProcoItem>();
  hover: boolean = false;

  displayedColumns: string[] = ["argument", "weight", "delete"];
  weightArray: number[];

  listUtils: ProcoListUtils;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.listUtils = new ProcoListUtils(null);

    if (this.column) {
      this.initWeightArray();
      this.updateData();
    }
  }

  initWeightArray() {
    if (this.list.type == ProcoType.Compare) {
      this.weightArray = this.initRange(-5, 5);
    } else {
      this.weightArray = this.initRange(1, 5);
    }
  }

  addItem(event): void {
    const newItemIndex = this.listUtils.getSortOrder(this.column.items)
    const itemNew = this.listUtils.createItem(this.column, newItemIndex);
    this.column.items.push(itemNew);

    this.updateData();
    this.parentApi.updateList(this.list);

    this.focusLastInsertedItem();
  }


  initRange(start, end): number[] {
    var numbers = [];
    for (var index = start; index <= end; index++) {
      numbers.push(index);
    }

    return numbers;
  }

  deleteColumn(event): void {
    this.translate.get(["DIALOG.DELETE_COLUMN_HEADER", "DIALOG.DELETE_COLUMN_CONTENT"]).subscribe((translations: string) => {
      let result = Object.keys(translations).map((key) => translations[key]);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          header: result[0],
          message: result[1] + " " + this.column.title
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.list.columns = this.list.columns.filter(column => column !== this.column);

          this.updateData();
          this.parentApi.updateList(this.list);
        }
      });
    });
  }

  inputChanged(event) {
    this.parentApi.inputChanged(event);
  }

  deleteItem(event, toDelete: ProcoItem): void {
    this.column.items = this.column.items.filter(item => item !== toDelete);

    this.updateData();
    this.parentApi.updateList(this.list);
  }

  getBackground(item: ProcoItem): string {
    let color = this.column.color;
    let weight = item.weight;
    let hexTransparency = "66"; // 40%

    if (weight < 0) {
      color += hexTransparency;
      weight = weight * -1;
    }

    let whitePercentage = ((10 - weight) / 10) * 100;
    let background = `linear-gradient(to left, white ${whitePercentage}%, ${color} 0%)`;
    return background;
  }

  enter(): void {
    this.hover = true;
  }

  leave(): void {
    this.hover = false;
  }

  onEnter(index: number) {
    const sortOrderNewItem = index + 1;
    const itemNew = this.listUtils.createItem(this.column, sortOrderNewItem);
    this.updateSortOrder(sortOrderNewItem)

    this.column.items.splice(sortOrderNewItem, 0, itemNew);

    this.updateData();
    this.parentApi.updateList(this.list);

    this.focusLastInsertedItem();
  }

  updateSortOrder(sortOrderNewItem: number) {
    for (let item of this.column.items) {
      if (item.sortOrder >= sortOrderNewItem) {
        item.sortOrder += 1;
      }
    }
  }

  updateData(): any {
    this.dataSource.data = this.column.items;
    this.cdRef.detectChanges();

    this.updateWeight();
  }

  focusLastInsertedItem() {
    this.cdRef.detectChanges();

    this.columnItems.last.nativeElement.focus();
  }

  weightChanged(): void {
    this.updateWeight();
    this.parentApi.updateList(this.list);
  }

  updateWeight() {
    this.parentApi.weightChanged();
  }

  isCompareType(): boolean {
    return this.list.type === ProcoType.Compare;
  }

  isProConType(): boolean {
    return this.list.type === ProcoType.ProCon;
  }
}
