<div class="rating" *ngIf="list">
  <!-- Statistic Information -->
  <div class="statistic" *ngIf="compactMode && ratings && ratings.length > 0">
    <div class="rating-compact" [ngStyle]="{ background: getCompactBackground() }"> </div>
    <div class="legend">
      <mat-grid-list>
        <mat-grid-tile *ngFor="let rating of ratings">
          <div class="legend-wrapper">
            <div class="legend-color" [style.background-color]="rating.color"></div>
            <div class="legend-name">{{rating.percentage}}% - {{rating.name}}</div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

    </div>
  </div>

  <div class="rating-full" *ngIf="!compactMode && ratings && ratings.length > 0">
    <table class="rating-table" mat-table [dataSource]="dataSource">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>
          {{"RATING.NAME" | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.name}}
        </mat-cell>
      </ng-container>

      <!-- Percentage Column -->
      <ng-container matColumnDef="percentage">
        <mat-header-cell *matHeaderCellDef>
          {{"RATING.PERCENTAGE" | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="percentage" [ngStyle]="{ background: getBackground(element) }">
          {{element.percentage}} %
        </mat-cell>
      </ng-container>

      <!-- Points Column -->
      <ng-container matColumnDef="points">
        <mat-header-cell *matHeaderCellDef>
          {{"RATING.POINTS" | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="points">
          {{element.points}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
  </div>
</div>