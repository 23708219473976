<div class="proco">
  <app-alert></app-alert>

  <div class="alert alert-warning" role="alert" *ngIf="showWarning">
    <mat-icon class="login-warning-icon">warning</mat-icon>
    {{ "OVERVIEW.LOGIN_WARNING" | translate }} <a routerLink="/login"
      class="alert-link">{{ "OVERVIEW.REGISTER_LINK" | translate }}</a>
  </div>

  <div class="overview" *ngIf="user && !editList">
    <div class="filter-icon">
      <mat-icon (click)="toggleFilterBar()">settings</mat-icon>
    </div>
    <div class="filter">
      <app-filter-bar [settings]="settings" [parentApi]="getParentApi()" *ngIf="showFilter"></app-filter-bar>
    </div>

    <div class="new-list">
      <!-- New pro contra list -->
      <button mat-raised-button (click)="newList(ProcoType.ProCon)">
        <img src="../../assets/img/btn_pro_con.svg" />
        <div>
          {{ "OVERVIEW.NEW_PROCO" | translate }}
        </div>
      </button>

      <!-- New compare list -->
      <button mat-raised-button (click)="newList(ProcoType.Compare)">
        <img src="../../assets/img/btn_pro_pro.svg" />
        <div>
          {{ "OVERVIEW.NEW_PROPRO" | translate }}
        </div>
      </button>

      <!-- New compare list -->
      <!--  <button mat-raised-button (click)="newList(ProcoType.Checklist)">
        <img src="../../assets/img/btn_checklist.svg" />
        <div>
          {{ "OVERVIEW.NEW_TODO" | translate }}
        </div>
      </button> -->
    </div>
  </div>

  <div class="current-list">
    <app-comparelist *ngIf="isCompareList()" [(compareList)]="editList" (closeEdit)="closeEditHandler($event)">
    </app-comparelist>
    <app-checklist *ngIf="isCheckList()" [(checkList)]="editList" (closeEdit)="closeEditHandler($event)">
    </app-checklist>
  </div>

  <div class="existing-lists" *ngIf="hasLists()">
    <!--Favorites -->
    <div [ngClass]="list.hidden ? 'list-item-hidden' : 'list-item'" (click)="openList(list)"
      *ngFor="let list of getFavoriteLists()">
      <app-compactlist [list]="list" (delete)="deleteList(list)" (update)="updateList(list)"></app-compactlist>
    </div>

    <mat-divider class="divider" *ngIf="getFavoriteLists().length > 0 && getActiveLists().length > 0">
    </mat-divider>

    <!--Other -->
    <div [ngClass]="list.hidden ? 'list-item-hidden' : 'list-item'" (click)="openList(list)"
      *ngFor="let list of getActiveLists()">
      <app-compactlist [list]="list" (delete)="deleteList(list)" (update)="updateList(list)"></app-compactlist>
    </div>

    <mat-expansion-panel class="archive" hideToggle *ngIf="getArchivedLists().length > 0"
      (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="archive-header">
        <mat-panel-title >
          {{ "OVERVIEW.ARCHIVE" | translate }}
        </mat-panel-title>
        <mat-panel-description>
          {{ (panelOpenState ? 'OVERVIEW.ARCHIVE_DESCRIPTION_HIDE' : 'OVERVIEW.ARCHIVE_DESCRIPTION') | translate}}
        </mat-panel-description>
        <mat-icon *ngIf="!panelOpenState">archive</mat-icon>
        <mat-icon *ngIf="panelOpenState">unarchive</mat-icon>
      </mat-expansion-panel-header>
      <!--Archive -->
      <div [ngClass]="list.hidden ? 'list-item-hidden' : 'list-item'" (click)="openList(list)"
        *ngFor="let list of getArchivedLists()">
        <app-compactlist [list]="list" (update)="updateList(list)"></app-compactlist>
      </div>
    </mat-expansion-panel>



  </div>
</div>