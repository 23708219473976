import { Component, OnInit } from "@angular/core";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Screenshot } from '../model/screenshot';

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit {

  procoImages: Array<Screenshot>;
  compareImages: Array<Screenshot>;
  todoImages: Array<Screenshot>;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initLanguageChangeListener();

    var path = this.translate.currentLang;
    this.initImages(path);
  }

  initLanguageChangeListener() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initImages(event.lang);
    });
  }

  initImages(path: string) {
    this.initProcoImages(path);
    this.initCompareImages(path);
    this.initTodoImages(path);
  }

  initTodoImages(path: string) {
    this.todoImages = new Array<Screenshot>();

    let screen1 = new Screenshot();
    screen1.path = `../../assets/img/sliders/${path}/todo1.png`;
    screen1.alt = "Todo list Screenshot 1";

    let screen2 = new Screenshot();
    screen2.path = `../../assets/img/sliders/${path}/todo2.png`;
    screen2.alt = "Todo list Screenshot 2";

    this.todoImages.push(screen1);
    this.todoImages.push(screen2);
  }

  initCompareImages(path: string) {
    this.compareImages = new Array<Screenshot>();

    let screen1 = new Screenshot();
    screen1.path = `../../assets/img/sliders/${path}/compare1.png`;
    screen1.alt = "Compare list Screenshot 1";

    let screen2 = new Screenshot();
    screen2.path = `../../assets/img/sliders/${path}/compare2.png`;
    screen2.alt = "Compare list Screenshot 1";

    this.compareImages.push(screen1);
    this.compareImages.push(screen2);
  }

  initProcoImages(path: string) {
    this.procoImages = new Array<Screenshot>();

    let screen1 = new Screenshot();
    screen1.path = `../../assets/img/sliders/${path}/proco1.png`;
    screen1.alt = "Pro Contra list Screenshot 1";

    let screen2 = new Screenshot();
    screen2.path = `../../assets/img/sliders/${path}/proco2.png`;
    screen2.alt = "Pro Contra list Screenshot 2";

    this.procoImages.push(screen1);
    this.procoImages.push(screen2);
  }

}
