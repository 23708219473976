import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import { CompareList } from '../model/comparelist';
import { ProcoList } from "../model/procolist";
import { Rating } from '../model/rating';
import { RatingUtils } from "../utils/rating-utils";

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"]
})
export class RatingComponent implements OnInit, OnChanges {
  @Input() list: ProcoList;
  @Input() compactMode: boolean = false;

  // Table
  dataSource = new MatTableDataSource<Rating>();
  displayedColumns: string[] = ["name", "percentage", "points"];

  ratingUtils = new RatingUtils();

  ratings: Rating[];

  constructor() {
  }

  ngOnInit() {
    this.setTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setTableData();
  }

  setTableData(): any {
    if (this.list == null) {
      return;
    }

    this.ratings = this.ratingUtils.calculateRatings(this.list as CompareList);
    this.dataSource.data = this.ratings;
  }

  getBackground(element: Rating): string {
    let whitePercentage = 100 - element.percentage;
    let color = element.color;

    let background = `linear-gradient(to left, white ${whitePercentage}%, ${color} 0%)`;
    return background;
  }

  getCompactBackground() {
    let compactColors = this.getCompactGradient();
    let background = `linear-gradient(to right, ${compactColors})`;
    return background;
  }

  getCompactGradient(): string[] {
    let gradient: string[] = [];

    let previous = 0;
    for (let rating of this.ratings) {
      let currentRow = `${rating.color} ${previous}%, ${rating.color} ${rating.percentage + previous}% `;
      gradient.push(currentRow);
      previous += rating.percentage;
    }

    return gradient;
  }

  /*  getColumnPoints(column: ProcoColumn, text: string, suffix: string): string {
     let points = this.ratingUtils.getColumnPoints(column);
     let pointsText = TextUtils.pluralize(points, text, suffix);
     return pointsText;
   } */
}
