import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { ListService } from "../list.service";
import { CheckListComponent } from '../lists/checklist/checklist.component';
import { CompareListComponent } from '../lists/comparelist/comparelist.component';
import { LoginService } from "../login.service";
import { OwnerType, ProcoList, ProcoType } from "../model/procolist";
import { UserSettings } from '../model/user-settings';
import { AlertType } from '../shared/alert/alert';
import { AlertComponent } from '../shared/alert/alert.component';
import { SpinnerService } from '../spinner.service';
import { ProcoListUtils } from '../utils/list-utils';
import { ScrollUtils } from '../utils/scroll-utils';
import { TextUtils } from '../utils/text-utils';

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"]
})
export class OverviewComponent implements OnInit {

  @ViewChild(AlertComponent, { static: true }) alertComponent: AlertComponent;
  @ViewChild(CompareListComponent) compareListComponent: CompareListComponent;
  @ViewChild(CheckListComponent) checkListComponent: CheckListComponent;

  ProcoType = ProcoType;
  existingLists: ProcoList[] = [];

  showFilter: boolean;
  showWarning: boolean;
  panelOpenState = false;

  editList: ProcoList;
  editListType: ProcoType;

  settings = new UserSettings();
  listUtils: ProcoListUtils;

  user: any;
  userSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private loginService: LoginService,
    private listService: ListService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private spinner: SpinnerService
  ) {
    this.listUtils = new ProcoListUtils(this.translate);
  }

  ngOnInit() {
    this.userSubscription = this.loginService.initUser().subscribe(user => {
      if (!user) {
        this.loginService.loginAsGuest();
        return;
      }

      this.init(user);
    });
  }

  init(user: any): void {
    this.user = user;

    this.openWithSecret();
    this.adaptWarning();
    this.initProcoLists();
  }

  openWithSecret(): void {
    const idToOpen = this.route.snapshot.params.id;
    const secret = this.route.snapshot.queryParams.secret;

    if (idToOpen && secret) {
      this.listService.getList(idToOpen, secret).subscribe((list) => {
        this.editList = list;

        if (this.isMyList(list)) {
          this.hideOpenList();
        }
      });
    }
  }

  isMyList(list: any): boolean {
    const existingList = this.getListForId(list.id);
    return existingList ? true : false;
  }

  openWithId(): void {
    const idToOpen = this.route.snapshot.params.id;
    const secret = this.route.snapshot.queryParams.secret;

    if (idToOpen && !secret) {
      const list = this.getListForId(idToOpen)
      if (list) {
        this.editList = list;
        this.hideOpenList();
      } else {
        this.addNoAccessAlert();
      }
    }
  }

  addNoAccessAlert() {
    this.translate.get('EDITLIST.NO_ACCESS').subscribe((message: string) => {
      this.alertComponent.addAlert(message, AlertType.Info);
    });
  }

  adaptWarning() {
    this.showWarning = this.loginService.isAnonymousUser();
  }

  initProcoLists(): any {
    this.listService.getLists().subscribe(result => {
      this.existingLists = result.lists;
      this.sortLists();
      this.openWithId();
      this.hideOpenList();
    });
  }

  refreshProcoLists(): any {
    this.sortLists();
    this.hideOpenList();
    this.spinner.hide();
  }

  hideOpenList() {
    if (this.editList) {
      const existingList = this.getListForId(this.editList.id);
      if (existingList) {
        existingList.hidden = true;
        this.editList.role = OwnerType.Owner;
      }
    }
  }

  newList(type: ProcoType): void {
    let proco;

    switch (type) {
      case ProcoType.ProCon:
        proco = this.listUtils.createCompareList(type);
        break;
      case ProcoType.Compare:
        proco = this.listUtils.createCompareList(type);
        break;
      case ProcoType.Checklist:
        proco = this.listUtils.createChecklist();
        break;
    }

    this.listUtils.createDefaultTitle(proco.type).subscribe((result: string) => {
      proco.title = result;

      this.listService.initList(proco).subscribe(result => {
        proco.id = result.id;
        proco.secret = result.secret;

        proco.hidden = true;
        this.existingLists.push(proco);
        this.editList = proco;
      });
    });
  }

  hasLists(): Boolean {
    return this.existingLists.length > 0
  }

  getFavoriteLists(): ProcoList[] {
    return this.existingLists.filter(list => !this.isHidden(list) && this.isFavorite(list) === true && this.isArchived(list) == false);
  }

  getActiveLists(): ProcoList[] {
    return this.existingLists.filter(list => !this.isHidden(list) && this.isFavorite(list) === false && this.isArchived(list) == false);
  }

  getArchivedLists():ProcoList[] {
    return this.existingLists.filter(list => !this.isHidden(list) && this.isArchived(list) === true);
  }

  isHidden(list: ProcoList): boolean {
    return list.hidden != null && list.hidden;
  }

  isFavorite(list: ProcoList): boolean {
    return list.favorite != null && list.favorite;
  }

  isArchived(list: ProcoList): boolean {
    return list.archived != null && list.archived;
  }

  closeEditHandler(event): void {
    if (event) {
      this.importList(event);
    }

    this.setHidden(false);
    this.updateInLists();

    this.editList = null;
    this.location.replaceState("lists");
    this.refreshProcoLists();
  }

  setHidden(hidden: boolean) {
    if (this.isCompareList()) {
      this.compareListComponent.compareList.hidden = hidden;
    } else if (this.isCheckList()) {
      this.checkListComponent.checkList.hidden = hidden;
    }
  }

  importList(importedList: ProcoList) {
    importedList.role = OwnerType.Owner;
    this.existingLists.push(importedList);
  }

  openList(list: ProcoList): any {
    if (this.editList) {

      if (this.isCompareList()) {
        this.compareListComponent.switch().subscribe((result) => {
          this.setHidden(false);
          this.switchList(list, true);
        });
      } else if (this.isCheckList()) {
        this.checkListComponent.switch().subscribe((result) => {
          this.setHidden(false);
          this.switchList(list, true);
        });
      }

      return;
    }

    this.switchList(list, false);
  }

  // open or switch between lists
  switchList(list: ProcoList, isSwitch: boolean) {
    if (isSwitch) {
      // update old list
      this.updateInLists();
    }

    // switch list
    this.editList = list;
    this.editList.hidden = true;

    this.updateRouterUrl();
    ScrollUtils.scrollToTop();
  }

  updateInLists() {
    if (this.isCompareList()) {
      this.existingLists = this.listUtils.replace(this.existingLists, this.compareListComponent.compareList);
    } else if (this.isCheckList()) {
      this.existingLists = this.listUtils.replace(this.existingLists, this.checkListComponent.checkList);
    }
  }

  updateRouterUrl() {
    this.location.replaceState("lists/" + this.editList.id);
  }

  getListForId(id: String): ProcoList {
    for (let list of this.existingLists) {
      if (list.id == id) {
        return list;
      }
    }
  }

  deleteList(list: ProcoList): void {
    this.translate.get(["DIALOG.DELETE_LIST_HEADER", "DIALOG.DELETE_LIST_CONTENT"]).subscribe((translations: string) => {
      let result = Object.keys(translations).map((key) => translations[key]);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          header: result[0],
          message: result[1] + " " + list.title
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          this.listService.deleteList(list.id).subscribe(() => {
            this.listUtils.remove(this.existingLists, list.id);
            this.refreshProcoLists();
          }, error => {
            this.spinner.hide();
          });
        }
      });
    });
  }

  updateList(list: ProcoList): void {
    this.spinner.show();
    this.listService.updateList(list).subscribe(() => {
      this.spinner.hide();
    });
  }

  isCompareList(): boolean {
    return this.editList && ProcoListUtils.isCompareList(this.editList);
  }

  isCheckList(): boolean {
    return this.editList && ProcoListUtils.isCheckList(this.editList);
  }

  toggleFilterBar(): void {
    this.showFilter = !this.showFilter;
  }

  sortLists(): void {
    let sortFunction = this.listUtils.determineListSort(this.settings);
    this.existingLists = this.existingLists.sort(sortFunction);
  }

  searchLists(): void {
    const search = this.settings.searchTerm;

    for (let list of this.existingLists) {
      if (this.showList(search, list)) {
        list.hidden = false;
      } else {
        list.hidden = true;
      }
    }
  }

  // TODO maybe also search the arguments/options
  showList(searchTerm: string, list: ProcoList): boolean {
    if (searchTerm == null || searchTerm.length === 0) {
      return true;
    }

    if (TextUtils.contains(searchTerm, list.title)) {
      return true;
    }

    return false;
  }

  settingsChanged(): void {
    this.sortLists();
    this.searchLists();
  }

  ngOnDestroy() {
    this.cleanUp();
  }

  cleanUp() {
    this.userSubscription.unsubscribe();
  }

  getParentApi(): OverviewComponentApi {
    return this;
  }

}

export interface OverviewComponentApi {
  settingsChanged: () => void;
}
