import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-share-list-dialog',
  templateUrl: './share-list-dialog.component.html',
  styleUrls: ['./share-list-dialog.component.scss']
})
export class ShareListDialogComponent implements OnInit {

  link: string;
  name: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    if (data) {
      this.link = data.link;
      this.name = data.name;
    }
  }

  ngOnInit() {
  }


  copyToClipboard() {
    document.addEventListener('copy', (event: ClipboardEvent) => {
      event.clipboardData.setData('text/plain', (this.link));
      event.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.translate.get('SHARE.COPY_CONFIRM').subscribe((res: string) => {
      this.snackBar.open(res, null,
        {
          duration: 2000,
          panelClass: ['light-snackbar']
        });
    });

  }

}
