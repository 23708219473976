export enum SortOption {
  MODIFIED_AT = "modified",
  CREATED_AT = "created",
  TITLE = "title"
}

export enum SortDirection {
  ASC = "asc",
  DESC = "desc"
}


export class UserSettings {
  sort: SortOption = SortOption.TITLE;
  sortDirection: SortDirection = SortDirection.ASC;
  searchTerm: string;
}
