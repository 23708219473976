import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProcoList } from "./model/procolist";
import { ServerconfigService } from "./serverconfig.service";

const URL_LISTS = "/lists";
const URL_LIST = "/list";

@Injectable({
  providedIn: "root"
})
export class ListService {

  headers = new HttpHeaders();

  constructor(private http: HttpClient, private config: ServerconfigService) { }

  initList(list: ProcoList): Observable<any> {
    let requestUrl = this.config.getRestUrl() + URL_LIST;
    return this.http.post(requestUrl, list, { headers: this.headers });
  }

  getLists(): Observable<any> {
    let requestUrl = this.config.getRestUrl() + URL_LISTS;
    return this.http.get(requestUrl, { headers: this.headers });
  }

  getList(listId: string, secret: string): Observable<any> {
    let params = new HttpParams();
    if (secret) {
      params = params.append('secret', secret);
    }

    let requestUrl = this.config.getRestUrl() + URL_LIST + "/" + listId;
    return this.http.get(requestUrl, { headers: this.headers, params: params });
  }

  updateList(proco: ProcoList): Observable<any> {
    let requestUrl = this.config.getRestUrl() + URL_LIST + "/" + proco.id;
    return this.http.post(requestUrl, proco, { headers: this.headers });
  }

  deleteList(listId: string): Observable<any> {
    let requestUrl = this.config.getRestUrl() + URL_LIST + "/" + listId;
    return this.http.delete(requestUrl, { headers: this.headers });
  }

}
