<div class="privacy">
  <h3>{{'PRIVACY.HEADER' |  translate }}</h3>
  <p>{{'PRIVACY.TEXT_GENERAL_1' |  translate }}</p>
  <div class="indent">
    <p>{{'PRIVACY.TEXT_GENERAL_2' |  translate }}</p>
  </div>
  <div>
    <h4>{{'PRIVACY.WHO_1' |  translate }}</h4>
    <p>{{'PRIVACY.WHO_2' |  translate }}</p>
    <div class="indent">
      <p><b>{{'PRIVACY.COMPANYNAME' |  translate }}</b></p>
      <p>{{'PRIVACY.ADDRESS' |  translate }}</p>
    </div>
    <p>{{'PRIVACY.IMPRINT' |  translate }} <a routerLink="/imprint">{{'NAV.IMPRINT' |  translate}}</a>.</p>
  </div>
  <div>
    <h4>{{'PRIVACY.COLLECT_1' |  translate }}</h4>
    <p>{{'PRIVACY.COLLECT_2' |  translate }}</p><br>
    <h5>{{'PRIVACY.COOKIES_1' |  translate }}</h5>
    <p>{{'PRIVACY.COOKIES_2' |  translate }}</p><br>
    <h5>{{'PRIVACY.REGISTER_1' |  translate }}</h5>
    <p>{{'PRIVACY.REGISTER_2' |  translate }}</p>
    <p>{{'PRIVACY.REGISTER_3' |  translate }}</p><br>
    <p>{{'PRIVACY.REGISTER_4' |  translate }}
      {{'PRIVACY.REGISTER_5' |  translate }} <a href="https://firebase.google.com/support/privacy"
        target="_blank">{{'PRIVACY.REGISTER_6' |  translate }}</a></p><br>
    <h5>{{'PRIVACY.CONTACT_1' |  translate }}</h5>
    <p>{{'PRIVACY.CONTACT_2' |  translate }}</p>
  </div>
</div>