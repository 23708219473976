<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <h1 routerLink="/">Proco</h1>

  <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/" (click)="closeNavbar()">{{'NAV.WHY' | translate}}</a>
      </li>

      <li class="nav-item" *ngIf="isLoggedIn()">
        <a class="nav-link" routerLink="/lists" (click)="closeNavbar()">{{'NAV.LISTS' | translate}}</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="https://freckled.dev/contact/">{{'NAV.FEEDBACK' | translate}}</a>
      </li>

      <li class="nav-item">
        <!-- <div class="nav-link username">{{getUsername()}}</div> -->
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn" ngbDropdownToggle>
            <img src="../../assets/img/account.svg" alt="{{'ALT.IMAGE.LOGGED_IN' | translate}}" *ngIf="isLoggedIn()">
            <img src="../../assets/img/account_login.svg" alt="{{'ALT.IMAGE.NOT_LOGGED_IN' | translate}}"
              *ngIf="!isLoggedIn()">
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <div class="nav-item login" *ngIf="!isLoggedIn() || !isRegistered()">
              <button class="dropdown" ngbDropdownItem routerLink="/login" (click)="closeNavbar()">{{'NAV.LOGIN' | translate}}</button>
            </div>
            <div *ngIf="isLoggedIn() && isRegistered()">
              <div class="manage">
                <button class="dropdown" ngbDropdownItem routerLink="/manage"
                  (click)="closeNavbar()">{{'NAV.SETTINGS' | translate}}</button>
              </div>
              <div class="logout">
                <div class="dropdown-divider"></div>
                <button class="dropdown" ngbDropdownItem (click)="logout()">{{'NAV.LOGOUT' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </li>

      <select class="language-select" (change)="switchLanguage($event.target.value)" [ngModel]="language">
        <option value="de">{{'NAV.GER' | translate}}</option>
        <option value="en">{{'NAV.ENG' | translate}}</option>
      </select>
    </ul>
  </div>
</nav>
<div class="top-bar proco-gradient"></div>