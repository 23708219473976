<div class="landing">

  <!-- Proco Advertising -->
  <div class="container landing-body">
    <div class="row">
      <div class="col landing-left my-auto">
        <h4>{{ 'LANDING.PROCO_DESCRIPTION_HEADER' | translate }}</h4>
        <p>{{ 'LANDING.PROCO_DESCRIPTION_CONTENT' | translate }}</p>
      </div>
      <div class="col landing-right">
        <img class="landing-picture" src="../../assets/img/landing_guy.jpg"
          alt="{{'ALT.IMAGE.LANDING_GUY' | translate}}" />
      </div>
    </div>
  </div>

  <!-- Pros/Cons lists -->
  <div class="procon" id="procon">
    <h2>{{ 'PROCON.HEADER' | translate }}</h2>

    <p>{{ 'PROCON.EXPLANATION' | translate}}</p>

    <h4>{{ 'PROCON.TEMPLATE_HEADER' | translate}}</h4>
    <div class="proco-template row">
      <div class="col-md-6 round-border-pro">
        <h5>{{ 'PROCON.TEMPLATE_PROS_HEADER' | translate}}</h5>
        <p> {{ 'PROCON.TEMPLATE_PROS_DESCRIPTION' | translate}}</p>
        <p> {{ 'PROCON.TEMPLATE_QUESTIONS' | translate}}</p>
        <ul>
          <li>{{ 'PROCON.TEMPLATE_PROS_Q1' | translate}}</li>
          <li>{{ 'PROCON.TEMPLATE_PROS_Q2' | translate}}</li>
          <li>{{ 'PROCON.TEMPLATE_PROS_Q3' | translate}}</li>
        </ul>
      </div>
      <div class="col-md-6 round-border-con">
        <h5>{{ 'PROCON.TEMPLATE_CONS_HEADER' | translate}}</h5>
        <p> {{ 'PROCON.TEMPLATE_CONS_DESCRIPTION' | translate}}</p>
        <p> {{ 'PROCON.TEMPLATE_QUESTIONS' | translate}}</p>
        <ul>
          <li>{{ 'PROCON.TEMPLATE_CONS_Q1' | translate}}</li>
          <li>{{ 'PROCON.TEMPLATE_CONS_Q2' | translate}}</li>
          <li>{{ 'PROCON.TEMPLATE_CONS_Q3' | translate}}</li>
        </ul>
      </div>
    </div>

    <div class="carousel-proco" *ngIf="procoImages.length > 0">
      <ngb-carousel #carousel interval="5000" [showNavigationArrows]="false">
        <ng-template ngbSlide *ngFor="let img of procoImages; index as i">
          <img class="carousel-image" [src]="img.path" alt="{{img.alt}}">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="container procon-container">
      <div class="row">
        <div class="col-sm">
          <img src="../../assets/img/decision.jpg" alt="{{'ALT.IMAGE.DECISION' | translate}}" />
          <h4>{{ 'PROCON.SIMPLICITY' | translate}}</h4>
          <p>{{ 'PROCON.SIMPLICITY_TEXT' | translate}}</p>
        </div>
        <div class="col-sm">
          <img src="../../assets/img/brain.png" alt="{{'ALT.IMAGE.BRAIN' | translate}}" />
          <h4>{{ 'PROCON.CLARITY' | translate}}</h4>
          <p>{{ 'PROCON.CLARITY_TEXT' | translate}}</p>
        </div>
        <div class="col-sm">
          <img src="../../assets/img/time.png" alt="{{'ALT.IMAGE.TIME' | translate}}" />
          <h4>{{ 'PROCON.TIME' | translate}}</h4>
          <p>{{ 'PROCON.TIME_TEXT' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Decision lists -->
  <div class="propro" id="propro">
    <h2>{{ 'PROPRO.HEADER' | translate}}</h2>

    <p>{{ 'PROPRO.EXPLANATION' | translate}}</p>

    <div class="carousel-compare" *ngIf="compareImages.length > 0">
      <ngb-carousel #carousel interval="5000" [showNavigationArrows]="false">
        <ng-template ngbSlide *ngFor="let img of compareImages; index as i">
          <img class="carousel-image" [src]="img.path" alt="{{img.alt}}">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="container propro-container">
      <div class="row">
        <div class="col-sm">
          <img src="../../assets/img/overview.png" alt="{{'ALT.IMAGE.OVERVIEW' | translate}}" />
          <h4>{{ 'PROPRO.OVERVIEW' | translate}}</h4>
          <p>{{ 'PROPRO.OVERVIEW_TEXT' | translate}}</p>
        </div>
        <div class="col-sm">
          <img src="../../assets/img/substantiated.png" alt="{{'ALT.IMAGE.SUBSTANTIATED' | translate}}" />
          <h4>{{ 'PROPRO.SUBSTANTIATED_DECISION' | translate}}</h4>
          <p>{{ 'PROPRO.SUBSTANTIATED_DECISION_TEXT' | translate}}</p>
        </div>
        <div class="col-sm">
          <img src="../../assets/img/money.png" alt="{{'ALT.IMAGE.MONEY' | translate}}" />
          <h4>{{ 'PROPRO.SAVE_MONEY' | translate}}</h4>
          <p>{{ 'PROPRO.SAVE_MONEY_TEXT' | translate}}</p>
        </div>
      </div>

      <div class="row next-row">
        <div class="col-sm">
          <img src="../../assets/img/options.png" alt="{{'ALT.IMAGE.OPTIONS' | translate}}" />
          <h4>{{ 'PROCON.NUMBER_LISTS' | translate}}</h4>
          <p>{{ 'PROCON.NUMBER_LISTS_TEXT' | translate}}</p>
        </div>
        <div class="col-sm">
          <img src="../../assets/img/chart.png" alt="{{'ALT.IMAGE.CHART' | translate}}" />
          <h4>{{ 'PROCON.RESULT' | translate}}</h4>
          <p>{{ 'PROCON.RESULT_TEXT' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- TODO lists -->
  <!-- <div class="todo" id="todo">
    <h2><span class="h2-colored">{{ 'TODO.HEADER_1' | translate}}</span> {{ 'TODO.HEADER_2' | translate}}</h2>

    <p>{{ 'TODO.EXPLANATION' | translate}}</p>

    <div class="carousel-todo" *ngIf="todoImages.length > 0">
      <ngb-carousel #carousel interval="5000" [showNavigationArrows]="false">
        <ng-template ngbSlide *ngFor="let img of todoImages; index as i">
          <img class="carousel-image" [src]="img.path" alt="{{img.alt}}">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="container todo-container">
    </div>
  </div> -->

</div>