import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { FirebaseErrors } from '../errors/firebase-errors';
import { LoginMethod, LoginService } from '../login.service';
import { AlertType } from '../shared/alert/alert';
import { AlertComponent } from '../shared/alert/alert.component';
import { ChangePasswordDialogComponent, InputGroup } from '../dialogs/change-password-dialog/change-password-dialog.component';

enum ManagementAction {
  Delete,
  ChangePassword
}

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {

  user: any;

  passwordOld: string;
  passwordNew: string;

  @ViewChild(AlertComponent, { static: true }) alertComponent: AlertComponent;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    const loggedIn = this.loginService.isLoggedIn();
    if (!loggedIn) {
      this.router.navigate(["/"]);
      return;
    }

    this.initUser();
  }

  initUser() {
    this.user = this.loginService.getUser();
  }

  getGreeting(): string {
    if (this.user == null) {
      return;
    }

    if (this.user.displayName != null) {
      return this.user.displayName;
    }

    return this.user.email;
  }

  getMail(): string {
    if (this.user == null) {
      return;
    }

    return this.user.email;
  }

  openDeleteDialog(): void {
    this.translate.get(["DIALOG.CONFIRM_DELETE", "DIALOG.CONFIRM_DELETE_TEXT"]).subscribe((translations: string) => {
      let result = Object.keys(translations).map((key) => translations[key]);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        height: '200px',
        data: {
          header: result[0],
          message: result[1]
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result === true) {
            this.deleteAccount();
          }
        }
      });
    });
  }

  deleteAccount(): void {
    this.loginService.deleteAccount()
      .then(() => {
        this.loginService.logout();
      })
      .catch(error => {
        this.handleError(error, ManagementAction.Delete);
      });
  }

  handleError(error: any, action: ManagementAction): void {
    if (error.code === FirebaseErrors.RECENT_LOGIN) {
      this.loginService.reauthenticate(this.passwordOld)
        .then(() => {
          this.ngZone.run(() => {
            if (action === ManagementAction.ChangePassword) {
              this.changePassword();
            }

            if (action === ManagementAction.Delete) {
              this.deleteAccount();
            }
          });
        })
        .catch(error => {
          this.handleError(error, action);
        });

      return;
    }

    this.alertComponent.addError(error);
  }

  openPasswordChangeDialog(): void {
    this.alertComponent.clearAlerts();

    this.translate.get(["MANAGE.PASSWORD_OLD_TEXT", "MANAGE.PASSWORD_OLD", "MANAGE.PASSWORD_NEW_TEXT",
      "MANAGE.PASSWORD_NEW", "MANAGE.PASSWORD_NEW_TEXT_REPEAT", "MANAGE.CHANGE"]).subscribe((message: string[]) => {
        let result = Object.keys(message).map((key) => message[key]);
        let inputGroup = this.createGroup(result);

        const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
          data: {
            inputs: inputGroup,
            buttonYes: result[5]
          }
        });

        dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            this.updatePassword(data);
          }
        });
      });
  }

  createGroup(result: any[]) {
    let loginGroup = new Array();

    let inputOld: InputGroup = {
      message: result[0],
      placeholder: result[1],
      type: 'password',
      text: ''
    }
    loginGroup.push(inputOld);

    let inputNew: InputGroup = {
      message: result[2],
      placeholder: result[3],
      type: 'password',
      text: ''
    }
    loginGroup.push(inputNew);

    let inputNewRepeat: InputGroup = {
      message: result[4],
      placeholder: result[3],
      type: 'password',
      text: ''
    }
    loginGroup.push(inputNewRepeat);

    return loginGroup;
  }

  getLoginMethod(): LoginMethod {
    return this.loginService.getLoginMethod();
  }

  getLoginMethodImg(): string {
    let method: LoginMethod = this.loginService.getLoginMethod();

    switch (method) {
      case LoginMethod.Facebook:
        return "../assets/img/fb.svg";
      case LoginMethod.Google:
        return "../assets/img/google.svg";
      case LoginMethod.Password:
        return "../assets/img/password.svg";
    }
  }

  pwChangeAllowed(): boolean {
    let method: LoginMethod = this.loginService.getLoginMethod();
    if (method === LoginMethod.Password) {
      return true;
    }

    return false;
  }

  updatePassword(data: any): void {
    this.passwordOld = data[0];
    this.passwordNew = data[1];

    this.loginService.reauthenticate(this.passwordOld)
      .then(() => {
        this.changePassword();
      })
      .catch(error => {
        this.handleError(error, ManagementAction.ChangePassword);
      });

  }

  changePassword() {
    this.loginService.updatePassword(this.passwordNew)
      .then(() => {
        this.updatePasswordSuccess();
      })
      .catch(error => {
        this.handleError(error, ManagementAction.ChangePassword);
      });
  }

  updatePasswordSuccess(): void {
    this.translate.get("MANAGE.CHANGE_PW_CONFIRM").subscribe((result: string) => {
      this.alertComponent.addAlert(result, AlertType.Success);
    });
  }

}
