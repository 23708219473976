<div *ngIf="data">

  <div mat-dialog-title>
    {{'MANAGE.CHANGE_PW' | translate}}
  </div>
  <div *ngFor="let input of data.inputs">
    <div mat-dialog-content>
      {{input.message}}
    </div>
    <div>
      <mat-form-field>
        <input matInput type="{{input.type}}" placeholder="{{input.placeholder}}" [(ngModel)]="input.text"
          autocomplete="off" />
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button (click)="onCancelClick()" cdkFocusInitial>{{'DIALOG.CANCEL' | translate}}</button>
    <button mat-raised-button color="warn" (click)="onConfirmClick()">{{data.buttonYes}}</button>
  </div>
</div>