<div class="list checklist" *ngIf="checkList">
  <ngb-alert [type]="'warning'" *ngIf="!isOwner()" class="not-owner" [dismissible]="false">
    {{'LISTVIEW.NOT_OWN_LIST' | translate}}
  </ngb-alert>
  <div [ngClass]="{'readonly': !isOwner()}">
    <!-- Title -->
    <div class="lists-title">
      <input class="title-input" [(ngModel)]="checkList.title" (ngModelChange)="inputChanged($event)"
        placeholder="{{'LISTVIEW.DEFAULT_TITLE' | translate}}" autocomplete="off"/>
    </div>

    <!-- Checkboxes todos -->
    <div class="checkboxes">
      <!-- Empty item to add new items -->
      <div class="new-item">
        <mat-icon class="new-item-icon" matTooltip="{{'TOOLTIP.NEW_ITEM' | translate }}">add</mat-icon>
        <input class="new-item-input" [(ngModel)]="newItemDescription" (ngModelChange)="addItem()"
          placeholder="{{'CHECKLIST.NEW_ITEM' | translate}}" autocomplete="off" />
      </div>

      <div class="existing-items">
        <div *ngFor="let item of getItems(false) | sortCheckItems; let index = index">
          <div (mouseenter)="item.hover=true" (mouseleave)="item.hover=false">
            <mat-checkbox [(ngModel)]="item.checked" (change)="checkItem($event)">
              <input #items class="existing-item-input" [(ngModel)]="item.description"
                (ngModelChange)="inputChanged($event)" (keyup.enter)="onEnter(index)" autocomplete="off" />
            </mat-checkbox>
            <mat-icon *ngIf="item.hover" class="delete-item" matTooltip="{{'TOOLTIP.DELETE_ITEM' | translate }}"
              (click)="deleteItem(item)">delete</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Checkboxes already finished -->
    <div class="checkboxes">
      <div *ngFor="let item of getItems(true) | sortCheckItems">
        <div (mouseenter)="item.hover=true" (mouseleave)="item.hover=false">
          <mat-checkbox class="disabled" [(ngModel)]="item.checked" (change)="checkItem($event)">
            {{item.description}}
          </mat-checkbox>
          <mat-icon *ngIf="item.hover" class="delete-item" matTooltip="{{'TOOLTIP.DELETE_ITEM' | translate }}"
            (click)="deleteItem(item)">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <!-- Bottom Button Menu -->
  <div class="bottom-menu">
    <!-- is owner -->
    <button mat-raised-button (click)="shareList()" *ngIf="isOwner()">
      {{ "SHARE.SHARE" | translate }}
    </button>

    <!-- is not the owner -->
    <button mat-raised-button matTooltip="{{'TOOLTIP.IMPORT_LIST' | translate }}" (click)="importList()"
      *ngIf="!isOwner()">
      {{ "EDITLIST.IMPORT_LIST" | translate }}
    </button>

    <button mat-raised-button (click)="close()">
      {{ "EDITLIST.CLOSE" | translate }}
    </button>
  </div>
</div>