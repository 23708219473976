import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export interface InputGroup {
  message: string;
  text: string;
  placeholder: string;
  type: string;
}

export interface DialogData {
  inputs: InputGroup[];
  buttonYes: string;
  buttonCancel: string;
}

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  onConfirmClick(): void {
    if (!this.inputValid()) {
      return;
    }

    const texts = this.data.inputs.map(group => group.text);
    this.dialogRef.close(texts);
  }

  inputValid(): boolean {
    return this.allFilled() && this.passwordsMatch() && this.passwordOk();
  }

  passwordOk(): boolean {
    const password1 = this.data.inputs[1].text;
    if (password1.length < 8) {
      this.translate.get("MANAGE.PASSWORD_TOO_SHORT").subscribe(message => {
        this.openSnackBar(message, "X");
      });
      return false;
    }

    return true;
  }

  passwordsMatch(): boolean {
    const password1 = this.data.inputs[1].text;
    const password2 = this.data.inputs[2].text;

    if (password1 !== password2) {
      this.translate.get("MANAGE.PASSWORD_MISMATCH").subscribe(message => {
        this.openSnackBar(message, "X");
      });
      return false;
    }

    return true;
  }

  allFilled(): boolean {
    for (let input of this.data.inputs) {
      if (input.text == null || input.text.length === 0) {
        this.translate.get("MANAGE.MISSING_INFO").subscribe(message => {
          this.openSnackBar(message, "X");
        });
        return false;
      }
    }

    return true;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
