import { ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ListService } from 'src/app/list.service';
import { CheckItem } from 'src/app/model/checkitem';
import { ProcoListUtils } from 'src/app/utils/list-utils';
import { CheckList } from '../../model/checklist';
import { ListComponent } from '../list/list.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss', '../list/list.component.scss']
})
export class CheckListComponent extends ListComponent implements OnInit {

  @Input() checkList: CheckList;

  @ViewChildren('items') items: QueryList<any>;

  listUtils: ProcoListUtils;

  newItemDescription: string;

  constructor(
    protected listService: ListService,
    protected dialog: MatDialog,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {
    super(listService, dialog);
  }

  ngOnInit() {
    this.listUtils = new ProcoListUtils(this.translate);
    this.initInputHandler();
  }

  initInputHandler() {
    this.inputChange
      .subscribe(() => {
        this.updateRunning = true;
      });

    this.inputSubscription = this.inputChange
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe((result) => {
        super.updateList(this.checkList);
      });
  }

  importList(): void {
    this.checkList.id = null;
    this.checkList.favorite = false;
    this.listService.initList(this.checkList).subscribe((result) => {
      this.checkList.id = result.id;
      this.checkList.secret = result.secret;
      this.closeEdit.emit(this.checkList);
    });
  }

  // used the new item line to insert an item on top
  addItem(): void {
    const sortOrderNewItem = 0
    let newItem = this.listUtils.createCheckItem(this.checkList, sortOrderNewItem);

    newItem.description = this.newItemDescription;

    this.updateSortOrder(sortOrderNewItem);
    this.insertItem(newItem, 0);
  }

  updateSortOrder(sortOrderNewItem: number) {
    for (let item of this.checkList.items) {
      if (item.sortOrder >= sortOrderNewItem) {
        item.sortOrder += 1;
      }
    }
  }

  insertItem(newItem: CheckItem, index: number): void {
    this.checkList.items.splice(index, 0, newItem);

    super.updateList(this.checkList);

    this.focusItemAtIndex(index);
  }

  focusItemAtIndex(index: number) {
    this.cdRef.detectChanges();

    this.items.toArray()[index].nativeElement.focus();
    this.newItemDescription = "";
  }

  onEnter(index: number) {
    const sortOrderNewItem = index + 1;
    let newItem = this.listUtils.createCheckItem(this.checkList, sortOrderNewItem);

    this.updateSortOrder(sortOrderNewItem);
    this.insertItem(newItem, sortOrderNewItem);
  }

  getItems(checked: boolean) {
    return this.checkList.items.filter(item => item.checked === checked);
  }

  deleteItem(toDelete: CheckItem) {
    this.checkList.items = this.checkList.items.filter(item => item !== toDelete);

    super.updateList(this.checkList);
  }

  checkItem(event): void {
    super.updateList(this.checkList);
  }

  public switch(): Observable<any> {
    return super.switch(this.checkList);
  }

  shareList(): void {
    super.shareList(this.checkList);
  }

  isOwner(): boolean {
    return super.isOwner(this.checkList);
  }

  close(): void {
    super.closeList(this.checkList);
  }

}
