export const environment = {
  production: true,
  host: "https://service.proco-list.com",
  port: 0,
  preUrl: "/api/v1",
  basicAuthUser: "",
  basicAuthPW: "",
  firebase: {
    apiKey: "AIzaSyCuJlWd3Oo7Rx83enS7sKqi08wE-nQL_zo",
    authDomain: "proco-lists.firebaseapp.com",
    databaseURL: "https://proco-lists.firebaseio.com",
    projectId: "proco-lists",
    storageBucket: "proco-lists.appspot.com",
    messagingSenderId: "828690759910"
  }/*  */
};
