import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseErrors } from 'src/app/errors/firebase-errors';
import { ScrollUtils } from 'src/app/utils/scroll-utils';
import { Alert, AlertType, AlertIcon } from './alert';
import { ErrorInterceptor } from 'src/app/error-interceptor';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { SpinnerService } from 'src/app/spinner.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  alerts: Alert[] = new Array();
  linkAlerts: Alert[] = new Array();

  constructor(
    private errorHandler: ErrorInterceptor,
    private translate: TranslateService,
    private spinner: SpinnerService
  ) { }

  ngOnInit() {
    this.subscribeHttpStatus();
  }

  subscribeHttpStatus() {
    this.errorHandler.httpError.subscribe(data => {
      this.clearAlerts();
      this.addAlert(data, AlertType.Danger);
    });
  }

  clearAlerts(): void {
    this.alerts = new Array();
    this.linkAlerts = new Array();
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  closeLink(alert: Alert) {
    this.linkAlerts.splice(this.alerts.indexOf(alert), 1);
  }

  performAction(alert: Alert) {
    alert.func.call(alert.caller);
  }

  addError(error: any): void {
    let errorMap = FirebaseErrors.getInstance().getErrorMap();

    let msgID = errorMap.get(error.code);
    if (msgID == null) {
      this.addAlert(error.message, AlertType.Danger);
      return;
    }

    this.translate.get(msgID).subscribe((result: string) => {
      this.addAlert(result, AlertType.Danger);
      this.scrollToAlerts();
    });
  }

  addLinkAlert(message: any, type: AlertType, func: () => void, caller: any): void {
    let alert = this.createAlert(message, type);
    alert.func = func;
    alert.caller = caller;

    this.linkAlerts.push(alert);
    this.scrollToAlerts();
  }

  addAlert(message: any, type: AlertType): void {
    let alert = this.createAlert(message, type);

    this.alerts.push(alert);
    this.scrollToAlerts();
  }

  private createAlert(message: string, type: AlertType) {
    let alert = new Alert();
    alert.icon = this.getIcon(type);
    alert.message = message;
    alert.type = type;
    return alert;
  }

  getIcon(type: AlertType): AlertIcon {
    if (type == AlertType.Danger) {
      return AlertIcon.Error;
    }
    if (type == AlertType.Warning) {
      return AlertIcon.Warning;
    }
    if (type == AlertType.Info) {
      return AlertIcon.Info;
    }
  }

  scrollToAlerts(): void {
    ScrollUtils.scrollToTop();
    this.spinner.hide();
  }



}
