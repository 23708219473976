


export class ProcoStorage {

  private static readonly PREFS_STORE_LANGUAGE: string = "language";

  static storeLanguage(language: string) {
    localStorage.setItem(ProcoStorage.PREFS_STORE_LANGUAGE, language);
  }

  static getLanguage(): string {
    return localStorage.getItem(ProcoStorage.PREFS_STORE_LANGUAGE);
  }

}