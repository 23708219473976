export class Alert {
  type: AlertType;
  message: string;
  icon: string;
  func: () => void;
  caller: any;
}

export enum AlertType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Danger = "danger"
}

export enum AlertIcon {
  Warning = "warning",
  Error = "error",
  Info = "info"
}