import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ErrorInterceptor implements HttpInterceptor {

  public httpError = new Subject<string>();

  constructor(
    private translate: TranslateService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            return this.handleHttpError(error);
          }

          return throwError(error);
        })
      );
  }

  handleHttpError(error: any): Observable<any> {
    if (error.status >= 500) {
      this.translate.get("ERROR.HTTP.SERVER_ERROR").subscribe(message => {
        this.httpError.next(`HTTP error (${error.status}): ` + message);
      });
    } else if (this.isUnauthorizedForListError(error)) {
      this.translate.get("ERROR.HTTP.UNAUTHORIZED").subscribe(message => {
        this.httpError.next(message);
      });
    } else {
      this.translate.get("ERROR.OTHER.CLIENT_ERROR").subscribe(message => {
        this.httpError.next(`HTTP error (${error.status}): ` + message);
      });

    }
    return of(error);
  }

  isUnauthorizedForListError(error: any): boolean {
    if (error.status === 403 && error.error.description.includes("must not access list")) {
      return true;
    }

    return false;
  }

}
