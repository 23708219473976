<div class="container filter-bar">
  <div class="row">

    <!-- Search the list titles -->
    <div class="col align-self-center">
      <mat-form-field>
        <input matInput [(ngModel)]="settings.searchTerm" (ngModelChange)="searchLists()"
          placeholder="{{'FILTER.SEARCH' | translate}}" autocomplete="off"/>
      </mat-form-field>
    </div>

    <!-- Sort property -->
    <div class="col sort-option align-self-center">
      <mat-select [(value)]="settings.sort" (selectionChange)="adaptSettings()">
        <mat-option value="created">{{'FILTER.SORT_OPTION_CREATED' | translate}}</mat-option>
        <mat-option value="modified">{{'FILTER.SORT_OPTION_MODIFIED' | translate}}</mat-option>
        <mat-option value="title">{{'FILTER.SORT_OPTION_TITLE' | translate}}</mat-option>
      </mat-select>
    </div>

    <!-- Sort direction -->
    <div class="col sort-direction align-self-center">
      <mat-select [(value)]="settings.sortDirection" (selectionChange)="adaptSettings()">
        <mat-option value="asc">{{'FILTER.SORT_DIRECTION_ASC' | translate}}</mat-option>
        <mat-option value="desc">{{'FILTER.SORT_DIRECTION_DESC' | translate}}</mat-option>
      </mat-select>
    </div>

  </div>
</div>