import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ShareListDialogComponent } from 'src/app/dialogs/share-list-dialog/share-list-dialog.component';
import { ListService } from 'src/app/list.service';
import { OwnerType, ProcoList } from 'src/app/model/procolist';
import { AlertType } from 'src/app/shared/alert/alert';
import { AlertComponent } from 'src/app/shared/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Output() closeEdit: EventEmitter<ProcoList> = new EventEmitter();

  @ViewChild(AlertComponent, { static: true }) alertComponent: AlertComponent;

  updateRunning: boolean;

  inputChange: Subject<ProcoList> = new Subject();
  inputSubscription: Subscription;

  constructor(
    protected listService: ListService,
    protected dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  closeList(list: ProcoList): void {
    if (!this.updateRunning) {
      this.closeEdit.emit(null);
      return;
    }

    this.inputSubscription.unsubscribe();
    this.listService.updateList(list)
      .subscribe(
        result => {
        },
        error => {
          this.alertComponent.addAlert(error, AlertType.Danger);
        },
        () => {
          this.updateRunning = false;
          this.closeEdit.emit(null);
        });
  }

  isOwner(list: ProcoList): boolean {
    return list.role === OwnerType.Owner;
  }

  shareList(list: ProcoList): void {
    this.dialog.open(ShareListDialogComponent, {
      data: {
        link: this.getLinkToList(list),
        name: list.title
      }
    });
  }

  updateList(list: ProcoList): void {
    this.updateRunning = true;

    this.listService.updateList(list)
      .subscribe(
        data => {
        },
        error => {
          this.alertComponent.addAlert(error, AlertType.Danger);
        },
        () => {
          this.updateRunning = false;
        });
  }

  inputChanged(event): void {
    this.inputChange.next(event);
  }

  getLinkToList(list: ProcoList): string {
    const host = window.location.host;
    return host + "/lists/" + list.id + "?secret=" + list.secret;
  }

  public switch(list: ProcoList): Observable<any> {
    if (!this.updateRunning) {
      return of({});
    }

    this.inputSubscription.unsubscribe();
    return this.listService.updateList(list);
  }


}
