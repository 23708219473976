import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-start-proco',
  templateUrl: './start-proco.component.html',
  styleUrls: ['./start-proco.component.scss']
})
export class StartProcoComponent implements OnInit {

  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
  }

  startProco(): void {
    this.navigate();
  }

  isLandingPage(): boolean {
    return this.router.url === "/" || this.router.url.startsWith("/#");
  }

  navigate(): void {
    this.router.navigateByUrl("/lists");
  }

  isLoggedIn(): boolean {
    return this.loginService.isLoggedIn();
  }

  isGuestUser(): boolean {
    return this.loginService.isGuestUser();
  }

}
