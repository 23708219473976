import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "src/app/login.service";
import { ProcoStorage } from '../storage/storage';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  navbarOpen = false;

  username: string;
  language: string;

  constructor(
    private translate: TranslateService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.setLanguageInCombo();
  }

  setLanguageInCombo() {
    this.language = ProcoStorage.getLanguage();
  }

  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }

  switchLanguage(language): void {
    this.language = language;

    this.translate.use(language);
    ProcoStorage.storeLanguage(language);
  }

  getUsername(): string {
    return this.loginService.getUserName();
  }

  isRegistered(): boolean {
    return this.loginService.isRegistered();
  }

  isLoggedIn(): boolean {
    return this.loginService.isLoggedIn();
  }

  logout(): void {
    this.loginService.logout();
    this.closeNavbar();
  }

  closeNavbar(): void {
    this.navbarOpen = false;
  }

}
