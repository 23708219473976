<div class="alerts" *ngIf="alerts">
  <div *ngFor="let alert of alerts">
    <ngb-alert [type]="alert.type" (close)="close(alert)">
      <mat-icon class="login-alert-icon" *ngIf="alert.icon">{{alert.icon}}</mat-icon>
      {{ alert.message }}
    </ngb-alert>
  </div>
  <div *ngFor="let alert of linkAlerts" class="link-alert">

    <ngb-alert (click)="performAction(alert)" [type]="alert.type" (close)="closeLink(alert)">
      <mat-icon class="login-alert-icon" *ngIf="alert.icon">{{alert.icon}}</mat-icon>
      {{ alert.message }}
    </ngb-alert>
  </div>
</div>