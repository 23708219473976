<div class="share" *ngIf="data">
  <div mat-dialog-title>
    {{'SHARE.SHARE_LIST' | translate}}: {{name}}
  </div>

  <div class="copy">
    <div class="copy-link">
      {{link}}
    </div>
    <mat-icon class="copy-icon" (click)="copyToClipboard()">content_copy</mat-icon>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]>{{'EDITLIST.CLOSE' | translate}}</button>
  </div>
</div>