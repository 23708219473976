<div class="imprint">
  <h3>{{'NAV.CONTACT' |  translate }}</h3>
  <div class="container info">
    <div class="row">
      <div class="col-md my-auto address-info">
        <p> <b>{{'PRIVACY.COMPANYNAME' |  translate }}</b> </p>
        <p>{{'PRIVACY.ADDRESS' |  translate }} </p>
        <br>
        <p>Email: <a href="mailto:contact@freckled.dev?subject=Proco">contact@freckled.dev</a></p>
        <p>Website: <a href="https://www.freckled.dev">www.freckled.dev</a></p>
        <p>Phone: +43 650 4451588</p>
      </div>
      <div class="col-md my-auto">
        <agm-map [latitude]="latCenter" [longitude]="lngCenter" [zoom]="zoom" [streetViewControl]="false">
          <agm-marker [latitude]="lat" [longitude]="lng">
            <agm-info-window [disableAutoPan]="false" #infoWindow>
              <div>{{'PRIVACY.COMPANYNAME' |  translate }}</div>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
    </div>
  </div>
</div>