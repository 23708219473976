import { Component, Input, OnInit } from '@angular/core';
import { UserSettings } from '../model/user-settings';
import { OverviewComponentApi } from '../overview/overview.component';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {

  @Input() settings = new UserSettings();
  @Input() parentApi: OverviewComponentApi;

  constructor() { }

  ngOnInit() {
  }

  adaptSettings(): void {
    this.parentApi.settingsChanged();
  }

  searchLists(): void {
    this.parentApi.settingsChanged();
  }
}
