
export class FirebaseErrors {

  private static instance: FirebaseErrors;

  private errorMap = new Map();

  public static WRONG_LOGIN: string = "auth/wrong-password";
  public static TOO_MANY_REQUESTS: string = "auth/too-many-requests";
  public static USER_DISABLED: string = "auth/user-disabled";
  public static USER_NOT_FOUND: string = "auth/user-not-found";
  public static CREDENTIALS_IN_USE: string = "auth/credential-already-in-use";

  public static ACCOUNT_ALREADY_EXISTS: string = "auth/email-already-in-use";
  public static BAD_MAIL: string = "auth/invalid-email";

  public static RECENT_LOGIN: string = "auth/requires-recent-login";
  public static NO_MATCH: string = "auth/user-mismatch";

  private constructor() {
    this.initErrors();
  }

  initErrors() {
    // login errors
    this.errorMap.set(FirebaseErrors.WRONG_LOGIN, "ERROR.FIREBASE.WRONG_LOGIN");
    this.errorMap.set(FirebaseErrors.TOO_MANY_REQUESTS, "ERROR.FIREBASE.TOO_MANY_REQUESTS");
    this.errorMap.set(FirebaseErrors.USER_DISABLED, "ERROR.FIREBASE.USER_DISABLED");
    this.errorMap.set(FirebaseErrors.USER_NOT_FOUND, "ERROR.FIREBASE.USER_NOT_FOUND");
    this.errorMap.set(FirebaseErrors.CREDENTIALS_IN_USE, "ERROR.FIREBASE.CREDENTIALS_IN_USE");

    // register errors
    this.errorMap.set(FirebaseErrors.ACCOUNT_ALREADY_EXISTS, "ERROR.FIREBASE.ACCOUNT_ALREADY_EXISTS");
    this.errorMap.set(FirebaseErrors.BAD_MAIL, "ERROR.FIREBASE.BAD_MAIL");

    // delete errors
    this.errorMap.set(FirebaseErrors.RECENT_LOGIN, "ERROR.FIREBASE.RECENT_LOGIN");
    this.errorMap.set(FirebaseErrors.NO_MATCH, "ERROR.FIREBASE.NO_MATCH");
  }

  getErrorMap() {
    return this.errorMap;
  }

  static getInstance(): FirebaseErrors {
    if (!FirebaseErrors.instance) {
      FirebaseErrors.instance = new FirebaseErrors();
    }

    return FirebaseErrors.instance;
  }
}