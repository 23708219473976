import { Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CheckItem } from '../model/checkitem';
import { CheckList } from '../model/checklist';
import { CompareList } from '../model/comparelist';
import { ColumnType, ProcoColumn } from "../model/prococolumn";
import { ProcoItem } from "../model/procoitem";
import { OwnerType, ProcoList, ProcoType } from "../model/procolist";
import { SortDirection, SortOption, UserSettings } from '../model/user-settings';
import { ProcoColor } from './proco-color';
import { v4 as uuidv4 } from 'uuid';


export class ProcoListUtils {

  constructor(
    @Optional() private translate: TranslateService
  ) { }

  createCompareList(type: ProcoType): CompareList {
    let comparelist = new CompareList();
    comparelist.type = type;
    comparelist.favorite = false;
    comparelist.role = OwnerType.Owner;

    const column1 = this.createColumn(comparelist);
    comparelist.columns.push(column1);
    const column2 = this.createColumn(comparelist);
    comparelist.columns.push(column2);

    return comparelist;
  }

  createChecklist(): CheckList {
    let checkList = new CheckList();
    checkList.type = ProcoType.Checklist;
    checkList.role = OwnerType.Owner;
    checkList.favorite = false;

    return checkList;
  }

  createCheckItem(checklist: CheckList, sortOrder: number): CheckItem {
    let item = new CheckItem();
    item.id = this.getId(checklist.items);
    item.sortOrder = sortOrder;

    item.description = "";
    item.checked = false;

    return item;
  }

  createItem(column: ProcoColumn, sortOrder: number): ProcoItem {
    const item = new ProcoItem();
    item.description = "";
    item.sortOrder = sortOrder;
    // we do not use this field atm - in the back-end it is mapped to the column 'user-id'
    item.id = uuidv4(); 
    return item;
  }

  private getColumnTitle(column: ProcoColumn, type: ProcoType): any {
    if (type === ProcoType.Compare) {
      return "Option " + (column.id + 1);
    }

    if (column.type === ColumnType.Pro) {
      return "Pros";
    }

    if (column.type === ColumnType.Con) {
      return "Cons";
    }
  }

  replace(lists: ProcoList[], newValue: ProcoList) {
    const index = lists.findIndex(list => list.id === newValue.id);
    if (index >= 0) {
      lists.splice(index, 1, newValue);
    }
    return lists;
  }

  remove(lists: ProcoList[], idToRemove: string) {
    const index = lists.findIndex(list => list.id === idToRemove);
    lists.splice(index, 1);
    return lists;
  }

  initColors(list: CompareList) {
    if (list == null) {
      return;
    }

    for (let column of list.columns) {
      column.color = this.getColumnColor(list, column);
    }
  }

  static isCompareList(list: ProcoList): boolean {
    if (!list) {
      return false;
    }

    return list.type === ProcoType.Compare || list.type === ProcoType.ProCon;
  }

  static isCheckList(list: ProcoList): boolean {
    if (!list) {
      return false;
    }

    return list && list.type === ProcoType.Checklist;
  }

  private getColumnColor(list: CompareList, column: ProcoColumn): any {
    if (column.type === ColumnType.Pro) {
      return ProcoColor.COLOR_PRO;
    }
    if (column.type === ColumnType.Con) {
      return ProcoColor.COLOR_CON;
    }

    if (column.type === ColumnType.Argument) {
      const index = list.columns.indexOf(column);
      if (index === -1) {
        return ProcoColor.COLORS_COMPARE[list.columns.length];
      }
      return ProcoColor.COLORS_COMPARE[index];
    }

    return ProcoColor.COLOR_WHITE;
  }

  createDefaultTitle(type: ProcoType): Observable<any> {
    let msgId;

    if (type === ProcoType.ProCon) {
      msgId = "OVERVIEW.NEW_PROCO";
    } else if (type === ProcoType.Compare) {
      msgId = "OVERVIEW.NEW_PROPRO";
    } else {
      msgId = "OVERVIEW.NEW_LIST";
    }

    return this.translate.get(msgId);
  }

  determineListSort(settings: UserSettings): any {
    const sort = settings.sort;
    const sortDirection = settings.sortDirection;
    let sortFunction;

    if (sort === SortOption.CREATED_AT) {
      if (sortDirection === SortDirection.ASC) {
        sortFunction = (list1, list2) => list1.date_added - list2.date_added;
      } else {
        sortFunction = (list1, list2) => list2.date_added - list1.date_added;
      }
    }

    if (sort === SortOption.MODIFIED_AT) {
      if (sortDirection === SortDirection.ASC) {
        sortFunction = (list1, list2) => list1.date_modified - list2.date_modified;
      } else {
        sortFunction = (list1, list2) => list2.date_modified - list1.date_modified;
      }
    }

    if (sort === SortOption.TITLE) {
      if (sortDirection === SortDirection.ASC) {
        sortFunction = (list1, list2) => list1.title.toLowerCase().localeCompare(list2.title.toLowerCase());
      } else {
        sortFunction = (list1, list2) => list2.title.toLowerCase().localeCompare(list1.title.toLowerCase());
      }
    }

    return sortFunction;
  }

  createColumn(proco: CompareList): any {
    const type = proco.type;
    const column = new ProcoColumn();
    column.id = this.getListId(proco);

    if (type === ProcoType.ProCon) {
      if (column.id == "0") {
        column.type = ColumnType.Pro;
      } else {
        column.type = ColumnType.Con;
      }
    }
    if (type === ProcoType.Compare) {
      column.type = ColumnType.Argument;
    }

    const item = this.createItem(column, 0);
    column.title = this.getColumnTitle(column, type);
    column.color = this.getColumnColor(proco, column);

    item.id = uuidv4();
    item.description = `${column.title} Argument 1`;
    column.items.push(item);

    return column;
  }

  private getListId(list: CompareList): any {
    const data = list.columns;
    return this.getId(data);
  }

  private getId(data: any): any {
    if (data.length === 0) {
      return 0;
    }

    const highestId = data.reduce(
      (max, p) => (p.id > max ? p.id : max),
      data[0].id
    );
    return highestId + 1;
  }

  getSortOrder(items: Array<any>): number {
    if (items.length == 0) {
      return 0;
    }

    const highest = items.reduce(
      (max, p) => (p.sortOrder > max ? p.sortOrder : max),
      items[0].sortOrder
    );

    return highest + 1
  }

}
