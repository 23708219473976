
export class ProcoList {
  id: string;
  title: string;
  secret: string;
  type: ProcoType;
  date_added: number;
  date_modified: number;
  hidden: boolean;
  role: OwnerType;
  favorite: boolean;
  archived: boolean;
}

export enum ProcoType {
  Compare = "compare",
  ProCon = "pro_con",
  Checklist = "todo"
}

export enum OwnerType {
  Owner = "owner"
}
