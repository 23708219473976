import { Pipe } from '@angular/core';
import { CheckItem } from '../model/checkitem';
import { ProcoItem } from '../model/procoitem';

@Pipe({
  name: "sortCheckItems"
})
export class SortCheckItemsPipe {
  transform(array: Array<CheckItem>, args: string): Array<CheckItem> {
    array.sort((item1: CheckItem, item2: CheckItem) => {
      if (item1.checked && !item2.checked) {
        return 1
      }

      if (!item1.checked && item2.checked) {
        return -1
      }

      if (item1.sortOrder > item2.sortOrder) {
        return 1;
      }

      if (item1.sortOrder < item2.sortOrder) {
        return -1;
      }

      return 0;
    });

    return array;
  }
}

@Pipe({
  name: "sortProcoItems"
})
export class SortProcoItemsPipe {
  transform(array: Array<ProcoItem>, args: string): Array<ProcoItem> {
    array.sort((item1: ProcoItem, item2: ProcoItem) => {
      if (item1.sortOrder > item2.sortOrder) {
        return 1;
      }

      if (item1.sortOrder < item2.sortOrder) {
        return -1;
      }

      return 0;
    });

    return array;
  }
}