
<div class="proco-gradient" *ngIf="isLandingPage()">
  <div class="container landing-header">
    <div class="row">
      <div class="col my-auto content-left">
        <button mat-raised-button (click)="startProco()">
          <div>{{ 'LANDING.OVERVIEW' | translate }}</div>
        </button>
      </div>
      <div class="col my-auto content-right">
        <span>
          <h1>{{ 'LANDING.SLOGAN1' | translate }}</h1>
          <h3>{{ 'LANDING.SLOGAN2' | translate }}</h3>
        </span>
      </div>
    </div>
  </div>
</div>