export class TextUtils {
  static pluralize(count: number, text: string, suffix: string) {
    return `${count} ${text}${count !== 1 ? suffix : ""}`;
  }

  static contains(searchTerm: string, toSearch: string) {
    if (toSearch.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
      return true;
    }

    return false;
  }
}
