import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ShareListDialogComponent } from '../../dialogs/share-list-dialog/share-list-dialog.component';
import { CompareList } from '../../model/comparelist';
import { ProcoList } from "../../model/procolist";
import { ProcoListUtils } from '../../utils/list-utils';
import { CheckItem } from 'src/app/model/checkitem';
import { CheckList } from 'src/app/model/checklist';

const NUM_CHECKITEMS_PREVIEW = 4;
@Component({
  selector: "app-compactlist",
  templateUrl: "./compactlist.component.html",
  styleUrls: ["./compactlist.component.scss"]
})
export class CompactListComponent implements OnInit {
  @Input() list: ProcoList;
  @Output() delete = new EventEmitter<string>();
  @Output() update = new EventEmitter<ProcoList>();

  hover: boolean;
  listUtils: ProcoListUtils = new ProcoListUtils(null);

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    if (ProcoListUtils.isCompareList(this.list)) {
      this.listUtils.initColors(this.list as CompareList);
    }
  }

  enter(): void {
    this.hover = true;
  }

  leave(): void {
    this.hover = false;
  }

  deleteList(event): void {
    this.delete.emit(this.list.id);

    // stop the after the click on the icon
    event.stopPropagation();
  }

  shareList(event): void {
    this.dialog.open(ShareListDialogComponent, {
      data: {
        link: this.getLinkToList(),
        name: this.list.title
      }
    });

    // stop the after the click on the icon
    event.stopPropagation();
  }

  setFavorite(favorite: boolean): void {
    this.list.favorite = favorite;

    this.update.emit(this.list)

    // stop the after the click on the icon
    event.stopPropagation();
  }

  setArchived(archived: boolean): void {
    this.list.archived = archived;

    this.update.emit(this.list)

    // stop the after the click on the icon
    event.stopPropagation();
  }

  getCheckitems(): Array<CheckItem> {
    let items = (this.list as CheckList).items;
    let sortedItems = new Array<CheckItem>();

    let checkedItems = items.filter(item => item.checked === false);
    sortedItems = sortedItems.concat(checkedItems);

    if (sortedItems.length >= NUM_CHECKITEMS_PREVIEW) {
      return sortedItems.slice(0, 4);
    }

    sortedItems = sortedItems.concat(items.filter(item => item.checked === true));
    return sortedItems.slice(0, 4);
  }

  isCompareList(): boolean {
    return ProcoListUtils.isCompareList(this.list);
  }

  isCheckList(): boolean {
    return ProcoListUtils.isCheckList(this.list);
  }

  getLinkToList(): string {
    const host = window.location.host;
    return host + "/lists/" + this.list.id + "?secret=" + this.list.secret;
  }

}
