import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { LoginService } from "./login.service";
import { ServerconfigService } from "./serverconfig.service";
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private config: ServerconfigService,
    private loginService: LoginService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isProcoRequest(request)) {
      return next.handle(request);
    }

    return from(this.loginService.getToken())
      .pipe(
        switchMap(idToken => {
          if (idToken) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${idToken}`
              }
            });
          }

          return next.handle(request);
        })
      );
  }

  isProcoRequest(request): boolean {
    return request.url.includes(this.config.getRestUrl());
  }
}
