<div *ngIf="column" (mouseenter)="enter()" (mouseleave)="leave()">
  <!-- Column Header -->
  <div class="column-header">
    <div class="proco-column-title" *ngIf="isProConType()">
      {{ column.title }}
    </div>
    <input class="column-title-editable" [(ngModel)]="column.title" (ngModelChange)="inputChanged($event)"
      *ngIf="isCompareType()" autocomplete="off" />
    <div class="delete-column-icon" *ngIf="hover && isCompareType()">
      <mat-icon matTooltip="{{'TOOLTIP.DELETE_COLUMN' | translate }}" (click)="deleteColumn($event)">delete</mat-icon>
    </div>
  </div>

  <!-- Column Content -->
  <div class="column-content">
    <table class="item-table" mat-table [dataSource]="dataSource">
      <!-- Argument Column -->
      <ng-container matColumnDef="argument">
        <mat-header-cell *matHeaderCellDef>{{
            "EDITLIST.ARGUMENT" | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index">
          <input #columnItems matInput [(ngModel)]="element.description" (ngModelChange)="inputChanged($event)"
            (keyup.enter)="onEnter(index)" autocomplete="off" />
        </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="weight">
        <mat-header-cell *matHeaderCellDef>{{
            "EDITLIST.WEIGHT" | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let element" [ngStyle]="{ background: getBackground(element) }">
          <mat-select class="weight-select" [(value)]="element.weight" (selectionChange)="weightChanged($event)">
            <mat-option *ngFor="let weight of weightArray" [value]="weight">{{
                weight
              }}</mat-option>
          </mat-select>
        </mat-cell>
      </ng-container>

      <!-- Delete Item -->
      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="delete-icon" (click)="deleteItem($event, element)">delete</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
    <div class="add-item">
      <button mat-raised-button (click)="addItem($event)">+</button>
    </div>
  </div>
</div>