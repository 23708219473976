import { Component, HostListener, OnInit } from '@angular/core';
import { ScrollUtils } from 'src/app/utils/scroll-utils';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit {

  windowScrolled: boolean;
  bottom: boolean;

  constructor(
  ) { }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (this.isWindowsScrolled()) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }

    if (this.isBottomReached()) {
      this.bottom = true;
    } else {
      this.bottom = false;
    }
  }

  isBottomReached() {
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if (pos == max) {
      return true;
    }

    return false;
  }

  isWindowsScrolled() {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100;
  }

  scrollToTop() {
    ScrollUtils.scrollToTop();
  }

  ngOnInit() { }

}
