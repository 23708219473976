import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {

  // Co-Working space location
  lat: number = 46.619172;
  lng: number = 14.282643;

  // center of klagenfurt
  latCenter: number = 46.624218;
  lngCenter: number = 14.308440;

  zoom: number = 12;

  constructor() { }

  ngOnInit() {
  }

}
