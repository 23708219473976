<div class="list" *ngIf="compareList">
  <ngb-alert [type]="'warning'" *ngIf="!isOwner()" class="not-owner" [dismissible]="false">
    {{'LISTVIEW.NOT_OWN_LIST' | translate}}
  </ngb-alert>
  <div [ngClass]="{'readonly': !isOwner()}">
    <div class="lists-title">
      <input class="title-input" [(ngModel)]="compareList.title" (ngModelChange)="inputChanged($event)"
        placeholder="{{'LISTVIEW.DEFAULT_TITLE' | translate}}" autocomplete="off" />
      <button mat-raised-button class="add-column" (click)="addColumn()"
        matTooltip="{{ 'LISTVIEW.TOOLTIP_NEW_COLUMN' | translate }}" [matTooltipPosition]="tooltipPosition"
        *ngIf="(compareList.type === type.Compare) && compareList.columns.length < 5">
        +
      </button>
    </div>

    <!-- Columns Component -->
    <div class="container columns">
      <div class="row justify-content-md-center">
        <div *ngFor="let column of compareList.columns" class="col-md-6 proco-column">
          <app-columnview [column]="column" [list]="compareList" [parentApi]="getParentApi()"></app-columnview>
        </div>
      </div>
    </div>

    <!-- Rating Component -->
    <div class="rating">
      <h5>{{'LISTVIEW.RESULT' | translate}}</h5>
      <app-rating [list]="compareList"></app-rating>
    </div>
  </div>

  <!-- Bottom Button Menu -->
  <div class="bottom-menu">
    <!-- is owner -->
    <button mat-raised-button (click)="shareList()" *ngIf="isOwner()">
      {{ "SHARE.SHARE" | translate }}
    </button>

    <!-- is not the owner -->
    <button mat-raised-button matTooltip="{{'TOOLTIP.IMPORT_LIST' | translate }}" (click)="importList()"
      *ngIf="!isOwner()">
      {{ "EDITLIST.IMPORT_LIST" | translate }}
    </button>

    <button mat-raised-button (click)="close()">
      {{ "EDITLIST.CLOSE" | translate }}
    </button>
  </div>
</div>