<div>
  <app-alert></app-alert>

  <div class="login-title">
    <h4>{{'LOGIN.TITLE' |  translate }}</h4>
  </div>

  <div class="container login">
    <div class="row">
      <!-- Existing -->
      <div class="col-md existing-customer">
        <h5>{{'LOGIN.EXISTING_CLIENT' |  translate }}</h5>
        <p>{{'LOGIN.EXISTING_GREETING' |  translate }}</p>

        <form autocomplete="off">
          <div class="login-process" *ngIf="!registerProcess">
            <div class="username">
              <mat-form-field appearance="outline">
                <mat-label>{{'LOGIN.USERNAME' |  translate }} ({{'LOGIN.MAIL' |  translate }})</mat-label>
                <input type="text" name="username" matInput required [(ngModel)]="credentials.username"  autocomplete="off"/>
              </mat-form-field>
            </div>

            <div class="password">
              <mat-form-field appearance="outline">
                <mat-label>{{'LOGIN.PASSWORD' |  translate }}</mat-label>
                <input type="password" name="password" matInput required [(ngModel)]="credentials.password" autocomplete="off" />
              </mat-form-field>
            </div>

            <div class="forgot-password">
              <a [routerLink]="" (click)="forgotPassword()">{{'LOGIN.FORGOT_PASSWORD' | translate}}</a>
            </div>
          </div>

          <div class="login-btn">
            <button mat-raised-button color="primary" (click)="loginWithUser()"
              *ngIf="registerProcess == false">{{'LOGIN.LOGIN' |  translate }}</button>
            <button mat-raised-button color="primary" (click)="registerProcess = false"
              *ngIf="registerProcess == true">{{'LOGIN.LOGIN' |  translate }}</button>
          </div>
        </form>

        <div class="other" *ngIf="!registerProcess">
          <div class="register-or">
            <p>{{'LOGIN.OR' |  translate }}</p>
          </div>
          <button class="btn facebook" (click)="login(method.Facebook)">
            {{'LOGIN.LOGIN_FACEBOOK' |  translate }}
          </button>

          <button class="btn google" (click)="login(method.Google)">
            {{'LOGIN.LOGIN_GOOGLE' |  translate }}
          </button>
        </div>
      </div>

      <!-- New -->
      <div class="col-md new-customer">

        <h5>{{'LOGIN.NEW_CLIENT' |  translate }}</h5>
        <p>{{'LOGIN.GREETINGS' |  translate }}</p>

        <div class="register-mail" *ngIf="registerProcess">
          <div class="username">
            <mat-form-field appearance="outline">
              <mat-label>{{'LOGIN.MAIL' |  translate }}</mat-label>
              <input type="text" matInput required [(ngModel)]="credentials.username" autocomplete="off" />
            </mat-form-field>
          </div>

          <div class="password">
            <mat-form-field appearance="outline">
              <mat-label>{{'LOGIN.PASSWORD' |  translate }} ({{'LOGIN.PASSWORD_RESTRICTION' |  translate }})
              </mat-label>
              <input type="password" matInput required [(ngModel)]="credentials.password" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{'LOGIN.PASSWORD_REPEAT' |  translate }}</mat-label>
              <input type="password" matInput required [(ngModel)]="credentials.repeatPassword" autocomplete="off"/>
            </mat-form-field>
          </div>

          <div class="privacy-policy">
            <h5>{{'LOGIN.PRIVACY_POLICY' |  translate }}</h5>
            <mat-checkbox [(ngModel)]="credentials.privacyPolicyAccepted">
              {{'LOGIN.PRIVACY_POLICY_TEXT_1' |  translate }}
              <a routerLink="/privacy">{{'LOGIN.PRIVACY_POLICY_TEXT_2' |  translate }}</a>
              {{'LOGIN.PRIVACY_POLICY_TEXT_3' |  translate }}.
            </mat-checkbox>
          </div>

        </div>

        <div class="register-btn">
          <button  mat-raised-button color="primary" (click)="registerProcess = true"
            *ngIf="registerProcess == false">{{'LOGIN.REGISTER' |  translate }}</button>
          <button  mat-raised-button color="primary" (click)="register()"
            *ngIf="registerProcess == true">{{'LOGIN.REGISTER_NOW' |  translate }}</button>
        </div>

      </div>
    </div>
  </div>
</div>