import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root"
})
export class ServerconfigService {
  private CONFIG_SERVER_HOST: string = environment.host;
  private CONFIG_SERVER_PORT: number = environment.port;
  private CONFIG_SERVER_PREURL: string = environment.preUrl;
  private CONFIG_SERVER_BASIC_AUTH_USER: string = environment.basicAuthUser;
  private CONFIG_SERVER_BASIC_AUTH_PW: string = environment.basicAuthPW;

  constructor() {
    this.init();
  }

  init() {}

  getRestUrl(): string {
    let serverUrl = this.CONFIG_SERVER_HOST;

    if (this.CONFIG_SERVER_PORT != 0) {
      serverUrl = serverUrl += ":" + this.CONFIG_SERVER_PORT;
    }

    if (this.CONFIG_SERVER_PREURL !== "") {
      serverUrl = serverUrl += this.CONFIG_SERVER_PREURL;
    }

    return serverUrl;
  }

  getBasicAuth(): string {
    if (
      this.CONFIG_SERVER_BASIC_AUTH_USER === "" ||
      this.CONFIG_SERVER_BASIC_AUTH_PW === ""
    ) {
      return "";
    }

    return (
      "Basic " +
      btoa(
        this.CONFIG_SERVER_BASIC_AUTH_USER +
          ":" +
          this.CONFIG_SERVER_BASIC_AUTH_PW
      )
    );
  }
}
