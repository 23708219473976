import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(
    private titleService: Title,
    private translate: TranslateService,
    private meta: Meta
  ) { }

  public initMetaTags() {
    this.initTitle();
    this.initDescription();
    this.initKeywords();
  }


  private initTitle() {
    this.translate.get("META.TITLE").subscribe((translation) => {
      this.titleService.setTitle(translation);
    });
  }

  private initDescription() {
    this.translate.get("META.DESCRIPTION").subscribe((translation) => {
      this.meta.addTag({ name: 'description', content: translation });
    });
  }

  private initKeywords() {
    this.translate.get("META.KEYWORDS").subscribe((translation) => {
      this.meta.addTag({ name: 'keywords', content: translation });
    });
  }

}
