import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TooltipPosition } from "@angular/material/tooltip";
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ListService } from "../../list.service";
import { CompareList } from '../../model/comparelist';
import { ProcoColumn } from "../../model/prococolumn";
import { ProcoType } from "../../model/procolist";
import { ProcoListUtils } from "../../utils/list-utils";
import { ListComponent } from '../list/list.component';

@Component({
  selector: "app-comparelist",
  templateUrl: "./comparelist.component.html",
  styleUrls: ["./comparelist.component.scss", "../list/list.component.scss"]
})
export class CompareListComponent extends ListComponent implements OnInit, ListViewComponentApi {

  @Input() compareList: CompareList;

  newElementId: any;
  type: any = ProcoType;
  tooltipPosition: TooltipPosition = "left";
  listUtils: ProcoListUtils;

  constructor(
    protected listService: ListService,
    protected dialog: MatDialog,
    private translate: TranslateService
  ) {
    super(listService, dialog);

    this.listUtils = new ProcoListUtils(this.translate);
  }

  ngOnInit() {
    this.initInputHandler();
    this.listUtils.initColors(this.compareList);
  }

  initInputHandler() {
    this.inputChange
      .subscribe(() => {
        this.updateRunning = true;
      });

    this.inputSubscription = this.inputChange
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe((result) => {
        super.updateList(this.compareList);
      });
  }

  addColumn(): void {
    const column = this.listUtils.createColumn(this.compareList);
    this.compareList.columns.push(column);
    this.updateList(this.compareList);
  }

  isOwner(): boolean {
    return super.isOwner(this.compareList);
  }

  calculateColumnPoints(column: ProcoColumn): number {
    return column.items.reduce((sum, current) => sum + current.weight, 0);
  }

  weightChanged(): void {
    // clone object, so that the ngOnChanges in the rating component is fired
    this.compareList = Object.assign({}, this.compareList);
    this.listUtils.initColors(this.compareList);
  }

  importList(): void {
    this.compareList.id = null;
    this.listService.initList(this.compareList).subscribe((result) => {
      this.compareList.id = result.id;
      this.compareList.secret = result.secret;

      this.closeEdit.emit(this.compareList);
    });
  }

  inputChanged(event): void {
    super.inputChanged(event);
  }

  public switch(): Observable<any> {
    return super.switch(this.compareList);
  }

  close(): void {
    super.closeList(this.compareList);
  }

  shareList(): void {
    super.shareList(this.compareList);
  }

  getParentApi(): ListViewComponentApi {
    return this;
  }

}

export interface ListViewComponentApi {
  weightChanged: () => void;
  inputChanged: (event) => void;
  updateList(list): void;
}
