<app-alert #managementAlert></app-alert>

<div class="manage" *ngIf="user">
  <h3>{{'MANAGE.TITLE' |  translate }}</h3>
  <div class="greeting">
    {{'MANAGE.HELLO' | translate}}, {{getGreeting()}}!
  </div>

  <div class="account">
    <h5>{{'MANAGE.ACCOUNT' | translate}}</h5>
    <p>{{'LOGIN.MAIL' | translate}}: {{getMail()}}</p>
    <div class="login-method">
      {{'MANAGE.LOGIN_METHOD' | translate}}: 
      {{getLoginMethod()}}
      <img src="{{getLoginMethodImg()}}" alt="{{'ALT.IMAGE.LOGIN_METHOD' | translate}}"/>
    </div>
  </div>

  <div class="change-password">
    <h5>{{'MANAGE.CHANGE_PW' | translate}}</h5>
    <p *ngIf="pwChangeAllowed()">{{'MANAGE.CHANGE_PW_TEXT' | translate}}</p>
    <p *ngIf="!pwChangeAllowed()">{{'MANAGE.CHANGE_PW_FOREIGN' | translate}}</p>
    <button mat-raised-button color="primary" (click)="openPasswordChangeDialog()" *ngIf="pwChangeAllowed()">{{'MANAGE.CHANGE_PW' | translate}}</button>
  </div>

  <div class="delete-account">
    <h5>{{'MANAGE.DELETE' | translate}}</h5>
    <p>{{'MANAGE.DELETE_INFO_1' | translate}}:</p>
    <p>{{'MANAGE.DELETE_INFO_2' | translate}}</p>
    <button class="btn btn-danger" (click)="openDeleteDialog()">{{'MANAGE.DELETE' | translate}}</button>
  </div>
</div>