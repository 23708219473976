export class ProcoColor {
  static readonly COLOR_WHITE = "#ffffff";

  // rating colors - procon
  static readonly COLOR_PRO = "#88c9ff";
  static readonly COLOR_CON = "#ffe183";

  // rating colors - compare
  static readonly COLORS_COMPARE: string[] = ["#a4dad5", "#FFCC80", "#e5c7eb", "#b9dfbb", "#fff599"];


}
