<div class="list-compact" (mouseenter)="enter()" (mouseleave)="leave()" [ngClass]="{ hover: hover }" *ngIf="list">
  <div class="header">
    <h5>{{ list.title }}</h5>
  </div>

  <!-- Actionbar-->
  <div class="hover-icons" *ngIf="hover">
    <!-- Favorite -->
    <mat-icon (click)="setFavorite(true)" *ngIf="!list.favorite" matTooltip="{{'TOOLTIP.PIN' | translate }}">
      favorite_border</mat-icon>
    <mat-icon (click)="setFavorite(false)" *ngIf="list.favorite" matTooltip="{{'TOOLTIP.UNPIN' | translate }}">favorite
    </mat-icon>

    <!-- Archive -->
    <mat-icon (click)="setArchived(true)" *ngIf="!list.archived" matTooltip="{{'TOOLTIP.ARCHIVE' | translate }}">
      archive</mat-icon>
    <mat-icon (click)="setArchived(false)" *ngIf="list.archived" matTooltip="{{'TOOLTIP.UNARCHIVE' | translate }}">
        unarchive</mat-icon>

    <!-- Share -->
    <mat-icon (click)="shareList($event)" matTooltip="{{'TOOLTIP.SHARE' | translate }}">share</mat-icon>

     <!-- Delete -->
    <mat-icon (click)="deleteList($event)" matTooltip="{{'TOOLTIP.DELETE' | translate }}">delete</mat-icon>
  </div>

  <div class="rating" *ngIf="isCompareList()">
    <app-rating [list]="list" [compactMode]="true"></app-rating>
  </div>

  <!-- Checklist -->
  <!-- <div class="items" *ngIf="isCheckList()">
    <div *ngFor="let item of list.items | sortCheckItems; let i=index">
      <div *ngIf="i<4">
        <div [ngClass]="(item.checked===true)?'item-checked':'item-unchecked'">
          {{item.description}}
        </div>
      </div>
    </div>
    <div *ngIf="list.items.length > 4">
      ...
    </div>
  </div> -->
</div>