import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'firebase';
import * as firebase from 'firebase/app';
import { from, Observable } from 'rxjs';
import { Credentials } from './model/credentials';


export enum LoginMethod {
  Facebook = "Facebook",
  Google = "Google",
  Password = "Password"
}

@Injectable({
  providedIn: "root"
})
export class LoginService {

  constructor(
    private afAuth: AngularFireAuth,
    private translate: TranslateService,
    private router: Router
  ) {
  }

  initUser(): Observable<any> {
    return this.afAuth.user;
  }

  public signInWithCredentials(credentials: Credentials, link: boolean, register: boolean): Promise<any> {
    if (link) {
      const credential = firebase.auth.EmailAuthProvider.credential(credentials.username, credentials.password);
      return this.getUser().linkWithCredential(credential);
    }

    if (register) {
      return this.afAuth.auth.createUserWithEmailAndPassword(credentials.username, credentials.password);
    }

    return this.afAuth.auth.signInWithEmailAndPassword(credentials.username, credentials.password);
  }

  public signInWithRedirect(method: LoginMethod, link: boolean): Promise<any> {
    let provider;

    if (method == LoginMethod.Facebook) {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    if (method == LoginMethod.Google) {
      provider = new firebase.auth.GoogleAuthProvider();
    }

    if (link) {
      return this.getUser().linkWithRedirect(provider);
    }

    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.afAuth.auth.signInWithRedirect(provider);
  }

  getRedirectResult(): Promise<any> {
    return this.afAuth.auth.getRedirectResult();
  }

  public sendEmailVerification(): Promise<any> {
    this.afAuth.auth.languageCode = this.translate.getBrowserLang();
    return this.getUser().sendEmailVerification();
  }

  public sendPasswordReset(mail: string): Promise<any> {
    this.afAuth.auth.languageCode = this.translate.getBrowserLang();
    return this.afAuth.auth.sendPasswordResetEmail(mail);
  }

  public loginAsGuest(): Promise<any> {
    return this.afAuth.auth.signInAnonymously();
  }

  public isAnonymousUser(): boolean {
    return this.getUser() != null && this.getUser().isAnonymous;
  }

  public isLoggedIn(): boolean {
    const user: User = this.getUser();
    if (user == null) {
      return false;
    }

    if (this.isMailVerified(user)) {
      return true;
    }

    return false;
  }

  public isMailVerified(user: User): boolean {
    if (user == null || user.providerData == null || user.providerData.length == 0) {
      return false;
    }

    let facebookUser = this.isFacebookUser(user);
    return user.emailVerified || facebookUser;
  }

  public isGuestUser(): boolean {
    const user: User = this.getUser();
    if (user && user.isAnonymous) {
      return true;
    }

    return false;
  }

  public refreshToken(): Observable<any> {
    // force refresh in this scenario
    if (this.getUser() == null) {
      return;
    }

    return from(this.getUser().getIdTokenResult(true));
  }

  public isRegistered(): boolean {
    const user: User = this.getUser();
    if (user && user.email != null) {
      return true;
    }

    return false;
  }

  public getToken(): Promise<string> {
    if (this.getUser() == null) {
      return Promise.resolve(null);
    }

    return this.getUser().getIdToken();
  }

  public updatePassword(password: string): Promise<any> {
    return this.getUser().updatePassword(password);
  }

  public reauthenticate(password: string): Promise<any> {
    let user = this.getUser();

    if (user == null || user.providerData == null || user.providerData.length === 0) {
      return;
    }

    if (this.isCredentialsUser(user)) {
      return this.reauthenticateWithCredentials(user.email, password);
    }

    return this.reauthenticateWithPopup(user);
  }

  public reauthenticateWithPopup(user: User): Promise<any> {
    let provider: any;

    if (this.isFacebookUser(user)) {
      provider = new firebase.auth.FacebookAuthProvider();
    }

    if (this.isGoogleUser(user)) {
      provider = new firebase.auth.GoogleAuthProvider();
    }

    return this.getUser().reauthenticateWithPopup(provider);
  }

  public reauthenticateWithCredentials(username: string, password: string): Promise<any> {
    const credential = firebase.auth.EmailAuthProvider.credential(
      username,
      password
    );

    return this.getUser().reauthenticateAndRetrieveDataWithCredential(credential);
  }

  public isGoogleUser(user: User): boolean {
    if (user == null) {
      return false;
    }
    
    for (let data of user.providerData) {
      if (data.providerId === 'google.com') {
        return true;
      }
    }

    return false;
  }

  public getLoginMethod(): LoginMethod {
    let user = this.getUser();

    if (this.isFacebookUser(user)) {
      return LoginMethod.Facebook;
    }

    if (this.isGoogleUser(user)) {
      return LoginMethod.Google;
    }

    if (this.isCredentialsUser(user)) {
      return LoginMethod.Password;
    }
  }

  public isFacebookUser(user: User): boolean {
    if (user == null) {
      return false;
    }

    for (let data of user.providerData) {
      if (data.providerId === 'facebook.com') {
        return true;
      }
    }

    return false;
  }

  public isCredentialsUser(user: User): boolean {
    if (user == null) {
      return false;
    }

    for (let data of user.providerData) {
      if (data.providerId === 'password') {
        return true;
      }
    }

    return false;
  }

  public getUserName(): string {
    const user = this.getUser();
    if (user == null || !this.isMailVerified(user)) {
      return null;
    }

    return user.email;
  }

  public getUser(): User {
    return this.afAuth.auth.currentUser;
  }

  public deleteAccount(): Promise<any> {
    return firebase.auth().currentUser.delete();
  }

  public logout(): void {
    this.afAuth.auth.signOut()
      .then(() => {
        this.router.navigateByUrl('/');
      });
  }

}
