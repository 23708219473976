import { ProcoColor } from '../utils/proco-color';
import { ProcoItem } from "./procoitem";

export enum ColumnType {
  Con = "con",
  Pro = "pro",
  Argument = "user-defined"
}

export class ProcoColumn {
  id: string;
  title: string;
  type: ColumnType;
  items = new Array<ProcoItem>();
  color: ProcoColor;
}
