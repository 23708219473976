import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { ForgotPasswordDialogComponent } from '../dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { FirebaseErrors } from '../errors/firebase-errors';
import { ValidationError } from '../errors/validation-errors';
import { ListService } from '../list.service';
import { LoginMethod, LoginService } from '../login.service';
import { Credentials } from '../model/credentials';
import { ProcoList } from '../model/procolist';
import { AlertType } from '../shared/alert/alert';
import { AlertComponent } from '../shared/alert/alert.component';
import { SpinnerService } from '../spinner.service';
import { ScrollUtils } from '../utils/scroll-utils';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild(AlertComponent, { static: true }) alertComponent: AlertComponent;

  credentials: Credentials = new Credentials();
  registerProcess: boolean = false;
  method = LoginMethod;


  constructor(
    private router: Router,
    private translate: TranslateService,
    private loginService: LoginService,
    private listService: ListService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private spinner: SpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.initRedirectResult();
  }

  initRedirectResult(): void {
    this.loginService.getRedirectResult()
      .then(result => {
        this.ngZone.run(() => {
          this.spinner.hide();

          if (this.isNotLoginRedirect(result)) {
            return;
          }
          this.handleLogin();
        });
      }).catch(error => {
        this.ngZone.run(() => {
          this.spinner.hide();

          if (this.popupAlreadyLinkedError(error)) {
            const loginMethod = this.getMethodFromError(error);
            this.handleAlreadyLinked(loginMethod);
            return;
          }

          this.addError(error);
        });
      });
  }

  getMethodFromError(error: any): LoginMethod {
    if (error.credential.signInMethod == "facebook.com") {
      return LoginMethod.Facebook;
    }

    return LoginMethod.Google;
  }

  isNotLoginRedirect(result: any): boolean {
    return result.user == null
      || (result.operationType !== "signIn" && result.operationType !== "link")
      || this.loginService.getUser() == null || this.loginService.isAnonymousUser();
  }

  handleUserNotVerified() {
    this.translate.get([ValidationError.MAIL_NOT_VERIFIED, 'LOGIN.RESEND_REGISTRATION_MAIL']).subscribe((message: string) => {
      let result = Object.keys(message).map((key) => message[key]);

      this.alertComponent.addAlert(result[0], AlertType.Danger);
      this.alertComponent.addLinkAlert(result[1], AlertType.Warning, this.resendVerification, this);
    });
  }

  handleLogin() {
    this.alertComponent.clearAlerts();
    this.navigate();
  }

  scrollToAlerts(): void {
    ScrollUtils.scrollToTop();
  }

  register(): void {
    this.alertComponent.clearAlerts();

    if (!this.validInput()) {
      return;
    }

    this.signInWithUser(true);
  }

  loginWithUser(): void {
    this.alertComponent.clearAlerts();

    if (!this.fieldsFilled()) {
      return;
    }

    this.signInWithUser(false);
  }

  signInWithUser(register: boolean): void {
    const anonymousUser = this.loginService.isAnonymousUser();
    const user = this.loginService.getUser();

    if (user == null) {
      this.signInWithCredentials(false, register);
      return;
    }

    this.listService.getLists()
      .subscribe(result => {
        // guest user - try linking the account, if there are guest lists 
        // (only works if no account has been created yet)
        if (anonymousUser && this.hasLists(result.lists)) {
          this.signInWithCredentials(true, register);
          return;
        }

        // normal sign in
        this.signInWithCredentials(false, register);
      });

  }

  signInWithCredentials(link: boolean, register: boolean): void {
    this.loginService.signInWithCredentials(this.credentials, link, register)
      .then((result) => {
        if (register) {
          this.loginService.sendEmailVerification().then(() => {
            this.registerProcess = false;

            this.translate.get("LOGIN.MAIL_VERIFICATION_SENT").subscribe((result: string) => {
              this.alertComponent.addAlert(result, AlertType.Success);
            });
          })
        } else {
          if (this.loginService.isMailVerified(result.user)) {
            this.handleLogin();
          } else {
            this.handleUserNotVerified();
          }
        }
      })
      .catch((error) => {
        if (link && this.credentialsAlreadyLinkedError(error)) {
          this.handleAlreadyLinked(LoginMethod.Password);
          return;
        }

        this.addError(error);
      });
  }

  resendVerification(): void {
    this.alertComponent.clearAlerts();

    this.loginService.sendEmailVerification()
      .then(() => {
        this.translate.get("LOGIN.RESEND_SUCCESS").subscribe((result: string) => {
          this.alertComponent.addAlert(result, AlertType.Success);
        });
      })
      .catch((error) => {
        this.addError(error);
      });
  }

  forgotPassword(): void {
    this.alertComponent.clearAlerts();

    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      data: {
        mail: this.credentials.username
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.loginService.sendPasswordReset(data.mail)
          .then(() => {
            this.translate.get("LOGIN.PASSWORD_RESEND_SUCCESS").subscribe((result: string) => {
              this.alertComponent.addAlert(result, AlertType.Success);
            });
          }).catch((error) => {
            this.addError(error);
          });
      }
    });
  }

  addError(error: any) {
    this.spinner.hide();
    this.alertComponent.addError(error);
  }

  validInput(): boolean {
    let msgID = ValidationError.getValidationErrors(this.credentials);

    if (msgID == null) {
      return true;
    }

    this.translate.get(msgID).subscribe((result: string) => {
      this.alertComponent.addAlert(result, AlertType.Warning);

    });

    return false;
  }

  fieldsFilled(): boolean {
    if (this.credentials.username == null || this.credentials.username.length === 0) {
      return false;
    }

    if (this.credentials.password == null || this.credentials.password.length === 0) {
      return false;
    }

    return true;
  }

  login(method: LoginMethod): void {
    this.alertComponent.clearAlerts();
    this.spinner.show();

    const anonymousUser = this.loginService.isAnonymousUser();
    const user = this.loginService.getUser();

    if (user == null) {
      this.loginService.signInWithRedirect(method, false);
      return;
    }

    this.listService.getLists()
      .subscribe(result => {
        // guest user - try linking the account, if there are guest lists 
        // (only works if no account has been created yet)
        if (anonymousUser && this.hasLists(result.lists)) {
          this.loginService.signInWithRedirect(method, true);
          return;
        }

        // normal sign in
        this.loginService.signInWithRedirect(method, false);
      });

  }

  hasLists(existingLists: ProcoList[]): boolean {
    if (existingLists != null && existingLists.length > 0) {
      return true;
    }

    return false;
  }

  // the user has created lists as a guest, and now wants to login into an existing account
  handleAlreadyLinked(method: LoginMethod): void {
    this.spinner.hide();

    if (method == LoginMethod.Password) {
      this.signInWithCredentials(false, false);
      return;
    }

    this.loginService.signInWithRedirect(method, false);
  }

  credentialsAlreadyLinkedError(error: any): boolean {
    if (error.code === FirebaseErrors.ACCOUNT_ALREADY_EXISTS) {
      return true;
    }

    return false;
  }

  popupAlreadyLinkedError(error: any): boolean {
    if (error.code === FirebaseErrors.CREDENTIALS_IN_USE) {
      return true;
    }

    return false;
  }

  navigate() {
    this.router.navigateByUrl('/lists');
  }

}
