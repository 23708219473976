import { Credentials } from '../model/credentials';

export class ValidationError {

  public static MISSING_USERNAME = "ERROR.VALIDATION.MISSING_USERNAME";
  public static MISSING_PASSWORD = "ERROR.VALIDATION.MISSING_PASSWORD";
  public static PASSWORD_MISMATCH = "ERROR.VALIDATION.PASSWORD_MISMATCH";
  public static PASSWORD_TOO_SHORT = "ERROR.VALIDATION.PASSWORD_TOO_SHORT";
  public static PP_NOT_ACCEPTED = "ERROR.VALIDATION.PP_NOT_ACCEPTED";
  public static MAIL_NOT_VERIFIED = "ERROR.VALIDATION.MAIL_NOT_VERIFIED"


  static getValidationErrors(credentials: Credentials): string {
    if (credentials.username == null || credentials.username.length === 0) {
      return this.MISSING_USERNAME;
    }

    if (credentials.password == null || credentials.password.length === 0) {
      return this.MISSING_PASSWORD;
    }

    if (credentials.password !== credentials.repeatPassword) {
      return this.PASSWORD_MISMATCH;
    }

    if (credentials.password.length < 8) {
      return this.PASSWORD_TOO_SHORT;
    }

    if (!credentials.privacyPolicyAccepted) {
      return this.PP_NOT_ACCEPTED;
    }

    return;
  }

}