import { CompareList } from '../model/comparelist';
import { ProcoColumn } from "../model/prococolumn";
import { Rating } from "../model/rating";

export class RatingUtils {

  overallPoints: number;

  public calculateRatings(proco: CompareList): Rating[] {
    this.overallPoints = this.calculateOverall(proco);
    let ratings: Rating[] = [];

    for (let column of proco.columns) {
      let rating = new Rating();

      rating.name = column.title;
      rating.points = this.getColumnPoints(column);
      rating.percentage = this.getPercentage(rating.points);
      rating.color = column.color;

      ratings.push(rating);
    }

    ratings.sort((left, right): number => {
      return left.points <= right.points ? 1 : -1;
    });

    return ratings;
  }

  calculateOverall(proco: CompareList): number {
    let overallPoints = 0;
    for (let column of proco.columns) {
      let columnPoints = this.getColumnPoints(column);
      if (columnPoints > 0) {
        overallPoints += columnPoints;
      }
    }

    return overallPoints;
  }

  getPercentage(columnPoints: number): number {
    if (columnPoints < 0) {
      return 0;
    }

    let percentage = (columnPoints / this.overallPoints) * 100;
    percentage = Math.round(percentage * 100) / 100
    return percentage;
  }

  getColumnPoints(column: ProcoColumn): number {
    return column.items.reduce((sum, current) => sum + current.weight, 0);
  }
}
