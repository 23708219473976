<div *ngIf="data">
  <h4>{{'LOGIN.NEW_PASSWORD' | translate}}</h4>
  <p>{{'LOGIN.FORGOT_PASSWORD_DETAILS_1' | translate}} </p>
  <p>{{'LOGIN.FORGOT_PASSWORD_DETAILS_2' | translate}}</p>

  <div class="forgot-password">
    <mat-form-field appearance="outline" class="mail-address">
      <mat-label>{{'LOGIN.MAIL' |  translate }}</mat-label>
      <input type="text" name="mail" matInput required [(ngModel)]="data.mail" autocomplete="off"/>
    </mat-form-field>
  </div>
  <button mat-raised-button color="primary" (click)="cancel()">{{'EDITLIST.CANCEL' |  translate }}</button>
  <button mat-raised-button color="primary" (click)="sendPasswordReset()" [disabled]="!data.mail || data.mail.length <=0">{{'LOGIN.SEND' |  translate }}</button>
</div>